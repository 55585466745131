<script setup lang="ts">
import { useAssignmentStore, type AssignmentData } from '@/stores/assignment'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { computed, reactive, ref, watch, defineAsyncComponent } from 'vue'
import { onBeforeMount } from 'vue'
import { ChevronLeftIcon, PlusIcon } from '@heroicons/vue/24/outline'
import CustomButton from '@/components/utils/CustomButton.vue'
import {
  AssignmentPurposeApiEnum,
  TaskTypeApiEnum,
  ReflectionTaskResponseTypeApiEnum,
  type Task
} from '@/open-api/generated'
import type { FeedbackTaskMeta, ConversationTaskMeta } from '@/types/api'
import type { CreatePreLearningTaskMeta, CreateReflectionTaskMeta } from '@/types/api'
import CustomInput, { type SelectOption } from '@/components/utils/CustomInput.vue'
import CustomPopover, { type PopoverOption } from '@/components/utils/CustomPopover.vue'
import DraggableTemplate from '@/components/utils/DraggableTemplate.vue'
import dayjs from 'dayjs'
import Api from '@/open-api'
import CustomModal from '@/components/utils/CustomModal.vue'
import ConversationTaskForm from '@/components/assignment-task-forms/ConversationTaskForm.vue'
import FeedbackTaskForm from '@/components/assignment-task-forms/FeedbackTaskForm.vue'
import PreLearningTaskForm from '@/components/assignment-task-forms/PreLearningTaskForm.vue'
import ReflectionTaskForm from '@/components/assignment-task-forms/ReflectionTaskForm.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import generateResourceUuid from '@/utils/generateResourceId'
import { sanitizeHtml } from '@/utils'
import { deepCopy } from '@/lib/utils'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import InputDatetimeLocal from '@/components/input/InputDatetimeLocal.vue'
import InputRichText from '@/components/input/InputRichText.vue'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'
import {
  focusLatestRichTextInput,
  focusLatestCustomInputText
} from '@/components/rich-text/focus-latest-rich-text-input'

// VR-related imports
import { isVrTaskPredicate } from '@/utils/vr'

const VRTaskForm = defineAsyncComponent({
  loader: () => import('@/vr/components/VRTaskForm.vue'),
  loadingComponent: AppLoadingSpinner
})

definePage({
  name: 'Cohort Assignment Details',
  meta: {
    permissionLevel: 'Educator'
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute('Cohort Assignment Details')
const router = useRouter()
const assignmentStore = useAssignmentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()

// ==================================================
// Assignment
// ==================================================
const newAssignment = () => ({
  assignment_id: '',
  name: '',
  description: '',
  visible: false,
  due_date: '',
  purpose: AssignmentPurposeApiEnum.NOTHING,
  tasks: []
})

const assignment = reactive({
  currentEditing: newAssignment() as AssignmentData,
  saveLoading: false,
  deleteModalStatus: -1,
  deleteLoading: false,
  convertLoading: false
})

const currentAssignment = computed(() => {
  return assignmentStore.editingCohort.assignments.find(
    (assignment) => assignment.assignment_id === route.params.assignmentId
  )
})

const currentAssignmentIndex = computed(() => {
  return assignmentStore.editingCohort.assignments.findIndex(
    (assignment) => assignment.assignment_id === route.params.assignmentId
  )
})

const isNewAssignment = computed(() => {
  return route.params.assignmentId === 'new'
})

onBeforeMount(() => {
  if (route.params.assignmentId !== 'new' && !currentAssignment.value) {
    router.push({ name: 'Cohorts' })
  } else if (currentAssignment.value) {
    assignment.currentEditing = deepCopy(
      assignmentStore.editingCohort.assignments[currentAssignmentIndex.value]
    )
    task.loading = true
    Api.Assignment.getAssignmentEndpoint(route.params.assignmentId as string)
      .then(async (res) => {
        const { assignment_id, assignment: assignmentData } = res

        const fixedAssignmentData = assignmentData

        // <Position fix>
        // Find out minimum task position (because there are some position starts with 1 at old platform)
        let minTaskPosition: number | undefined = undefined

        fixedAssignmentData.tasks.forEach((task) => {
          if (minTaskPosition === undefined || task.position < minTaskPosition) {
            minTaskPosition = task.position
          }
        })

        // <Position fix>
        if (minTaskPosition !== undefined && minTaskPosition !== 0) {
          // Old platform task position starts with 1, update position to 0
          fixedAssignmentData.tasks.forEach((task, index) => {
            task.position = index
          })
          await updateTaskPositions(fixedAssignmentData.tasks)
        }

        const update = deepCopy(assignmentStore.editingCohort)
        update.assignments[currentAssignmentIndex.value] = deepCopy({
          assignment_id,
          ...fixedAssignmentData
        })

        assignmentStore.updateEditingCohort(update)
        assignment.currentEditing = deepCopy({
          assignment_id,
          ...assignmentData
        }) as AssignmentData
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
        router.push({ name: 'Cohorts' })
      })
      .finally(() => {
        task.loading = false
      })
  }
})

onBeforeRouteLeave((to, from, next) => {
  if (!canSave.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

const canSave = computed(() => {
  const { description, due_date, name, purpose, visible } = assignment.currentEditing

  const storeEditingJSON = JSON.stringify(currentAssignment.value)
  const componentEditingJSON = JSON.stringify(assignment.currentEditing)

  return (
    !description.trim() ||
    !due_date ||
    !name.trim() ||
    !purpose ||
    typeof visible !== 'boolean' ||
    storeEditingJSON === componentEditingJSON
  )
})

const saveEditAssignment = () => {
  assignment.saveLoading = true
  assignment.currentEditing.description = sanitizeHtml(assignment.currentEditing.description)
  Api.Assignment.updateAssignmentEndpoint(assignment.currentEditing)
    .then(() => {
      const cohortUpdate = deepCopy(assignmentStore.editingCohort)

      cohortUpdate.assignments[currentAssignmentIndex.value] = deepCopy(assignment.currentEditing)

      assignmentStore.updateEditingCohort(cohortUpdate)

      notificationStore.addNotification({
        subtitle: 'Assignment successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      assignment.saveLoading = false
    })
}

const createAssignment = () => {
  assignment.saveLoading = true
  assignment.currentEditing.description = sanitizeHtml(assignment.currentEditing.description)
  Api.Assignment.createAssignmentEndpoint({
    assignment: assignment.currentEditing,
    cohort_id: route.params.cohortId as string
  })
    .then((res) => {
      const { assignment_id } = res

      assignment.currentEditing.assignment_id = assignment_id
      const cohortUpdate = deepCopy(assignmentStore.editingCohort)

      cohortUpdate.assignments.push(deepCopy(assignment.currentEditing))

      assignmentStore.updateEditingCohort(cohortUpdate)

      router.push({
        name: 'Cohort Assignment Details',
        params: { assignmentId: assignment_id, cohortId: route.params.cohortId }
      })

      notificationStore.addNotification({
        subtitle: 'Assignment successfully created',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      assignment.saveLoading = false
    })
}

const createCloneOfCurrentEditingAssignment = () => {
  const clonedAssignment = deepCopy(assignment.currentEditing) as any

  delete clonedAssignment.assignment_id

  clonedAssignment.name = `copy of ${clonedAssignment.name} ${dayjs().format(
    'DD/MM/YYYY HH:mm:ss'
  )}`

  if (dayjs(clonedAssignment.due_date).isBefore(dayjs())) {
    clonedAssignment.due_date = dayjs().add(1, 'days')
  }

  clonedAssignment.visible = false
  clonedAssignment.tasks = []
  return clonedAssignment
}

const convertToActivity = async () => {
  try {
    assignment.convertLoading = true
    const assignmentId: string = assignment.currentEditing.assignment_id
    const activityId = generateResourceUuid('assignment_id')
    await Api.V2.Assignments.createActivityFromAssignmentEndpoint(assignmentId, activityId)

    router.push({
      name: 'Modern Activities - Edit Activity',
      params: { activityId }
    })

    notificationStore.addNotification({
      subtitle: 'Assignment successfully converted',
      status: NotificationStatus.SUCCESS
    })
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    assignment.convertLoading = false
  }
}

const cloneAssignment = async () => {
  try {
    assignment.saveLoading = true
    const clonedAssignment = createCloneOfCurrentEditingAssignment()
    const { assignment_id } = await Api.Assignment.createAssignmentEndpoint({
      assignment: clonedAssignment,
      cohort_id: route.params.cohortId as string
    })
    const tasks = assignment.currentEditing.tasks
    await Promise.all(
      tasks.map((task) => {
        return Api.Assignment.createTaskEndpoint({
          assignment_id: assignment_id,
          task: task
        })
      })
    )
    router.push({
      name: 'Cohort Assignments',
      params: { cohortId: assignmentStore.editingCohort.cohort_id },
      query: { search: clonedAssignment.name }
    })

    notificationStore.addNotification({
      subtitle: 'Assignment successfully cloned',
      status: NotificationStatus.SUCCESS
    })
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    assignment.saveLoading = false
  }
}

const deleteAssignment = () => {
  assignment.deleteLoading = true
  Api.Assignment.deleteAssignmentEndpoint({
    assignment_id: assignment.currentEditing.assignment_id
  })
    .then(() => {
      router.push({
        name: 'Cohort Assignments',
        params: { cohortId: assignmentStore.editingCohort.cohort_id }
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      assignment.deleteLoading = false
    })
}

// ==================================================
// Tasks
// ==================================================
const reflectionTemplateSelected = ref(false)
const task = reactive({
  currentEditing: undefined as Task | undefined,
  saveLoading: false,
  loading: false,
  deleteModalStatus: -1
})

const taskName = computed(() => {
  if (isVrTask.value) {
    return 'VR task'
  } else if (task?.currentEditing?.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
    return 'Conversation task'
  } else if (task?.currentEditing?.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
    return 'Feedback task'
  } else if (task?.currentEditing?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK) {
    return 'Pre-learning task'
  } else if (task?.currentEditing?.task_type === TaskTypeApiEnum.REFLECTION_TASK) {
    return 'Reflection task'
  } else {
    return 'Task'
  }
})

const toTask = (task: any) => {
  assignmentStore.setCurrentCohortTask(task.element)

  router.push({
    name: 'Cohort Assignment Task Details',
    params: {
      cohortId: assignmentStore.editingCohort.cohort_id,
      assignmentId: assignment.currentEditing.assignment_id,
      taskId: task.element.task_id
    }
  })
}

const closeModal = () => {
  const answer = window.confirm(
    'Warning: You have unsaved changes. \nClosing this modal will delete any unsaved changes'
  )
  if (answer) {
    task.currentEditing = undefined
  }
}

const saveTask = () => {
  if (!task.currentEditing) {
    return
  }

  task.saveLoading = true

  if (!task.currentEditing.description) {
    task.currentEditing.description = sanitizeHtml(assignment?.currentEditing.description)
  }

  Api.Assignment.createTaskEndpoint({
    assignment_id: assignment?.currentEditing?.assignment_id,
    task: task.currentEditing
  })
    .then((res) => {
      const { task_id } = res

      const update = deepCopy(assignmentStore.editingCohort)

      update.assignments[currentAssignmentIndex.value].tasks.push({
        ...(task.currentEditing as Task),
        task_id
      })

      assignmentStore.updateEditingCohort(update)
      assignment.currentEditing.tasks.push({
        ...(task.currentEditing as Task),
        task_id
      })

      task.currentEditing = undefined
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      task.saveLoading = false
    })
}

const changeTasks = async ($event: any) => {
  if ($event.moved) {
    const { oldIndex, newIndex } = $event.moved
    if (assignment.currentEditing) {
      const tasksClone = deepCopy(assignment.currentEditing?.tasks)
      const movedTasks = moveArrayElement(
        assignment.currentEditing?.tasks,
        'position',
        oldIndex,
        newIndex
      )

      const success = await updateTaskPositions(movedTasks)

      // Revert if not success
      if (!success) {
        assignment.currentEditing.tasks = tasksClone
      }
    }
  }
}

/**
 * Move array element (mutates the array)
 * @param {Record<string, any>[]} array - The array containing the element to be moved
 * @param {string} positionField - position field name
 * @param {number} fromIndex - The index of the element to be moved
 * @param {number} toIndex - The index to move the element to
 */
function moveArrayElement<T extends Record<string, any>, S extends keyof T>(
  array: T[],
  positionField: S,
  fromIndex: number,
  toIndex: number
) {
  // Get the object to be moved
  const movedObject = array[fromIndex]

  // Move the object to the desired position
  array.splice(fromIndex, 1)
  array.splice(toIndex, 0, movedObject)

  // Determine the range of affected objects (inclusive)
  const startIndex = Math.min(fromIndex, toIndex)
  const endIndex = Math.max(fromIndex, toIndex)

  // Update the "position" field for the affected objects
  for (let i = startIndex; i <= endIndex; i++) {
    array[i][positionField] = i as T[S]
  }

  // Return only the updated objects
  const updatedObjects = array.slice(startIndex, endIndex + 1)

  return updatedObjects
}

/**
 * Given an array of records, moves the record at the specified index to the end
 * of the array.
 *
 * Also updates the `position` field of these records, so that the `i`-th
 * element `R` has `R[positionField] = i`.
 *
 * Does not mutate the original array, returns a deep copy of the original array
 * with the changes.
 */
function moveElementToEnd<T extends Record<string, any>, S extends keyof T>(
  array: T[],
  positionField: S,
  index: number
) {
  // Clone.
  const updatedArray = deepCopy(array)

  // Shift.
  const movedElement = updatedArray.splice(index, 1)[0]
  updatedArray.push(movedElement)

  // Update the `position` field.
  for (let i = 0; i < updatedArray.length; i++) {
    updatedArray[i][positionField] = i as T[S]
  }

  return updatedArray
}

// Update multiple task positions
const updateTaskPositions = async (tasks: Task[]) => {
  try {
    task.loading = true
    await Api.Assignment.updateTaskPositionsEndpoint({
      assignment_id: route.params.assignmentId as string,
      task_positions: tasks.map((task) => ({
        task_id: task.task_id,
        position: task.position
      }))
    })
    task.loading = false

    return true
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
    task.loading = false
    return false
  }
}

const deleteTask = async () => {
  /* ------ Move deleted task to the end of position before delete ------ */
  // Clone task for revert
  const tasksClone = deepCopy(assignment.currentEditing.tasks)

  // Move deleted task to the end of the array
  const movedTasks = moveElementToEnd(
    assignment.currentEditing.tasks,
    'position',
    task.deleteModalStatus
  )

  // Update task position
  const success = await updateTaskPositions(movedTasks)

  // Revert if not success
  if (!success) {
    assignment.currentEditing.tasks = tasksClone
    return
  }

  Api.Assignment.deleteTaskEndpoint({
    task_id: assignment.currentEditing.tasks[task.deleteModalStatus].task_id
  })
    .then(() => {
      const update = deepCopy(assignmentStore.editingCohort)

      update.assignments[currentAssignmentIndex.value].tasks.splice(task.deleteModalStatus, 1)

      assignmentStore.updateEditingCohort(update)
      assignment.currentEditing.tasks.splice(task.deleteModalStatus, 1)

      notificationStore.addNotification({
        subtitle: 'Task successfully deleted',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      task.deleteModalStatus = -1
    })
}

// initialize rubric and character options on opening task modal
watch(
  () => task.currentEditing,
  (newVal, oldVal) => {
    if (
      (!oldVal && newVal && (newVal?.meta as ConversationTaskMeta).ConversationTask) ||
      isVrTask.value
    ) {
      searchRubrics()
      searchCharacters()
    }
  }
)

// Task Rubrics
const rubric = reactive({
  options: [] as SelectOption[],
  search: false,
  searchLoading: false
})

const searchRubrics = async (searchString = EMPTY_STRING_SEARCH_ALL) => {
  if (searchString === '') {
    searchString = EMPTY_STRING_SEARCH_ALL
  }
  rubric.searchLoading = true
  Api.Content.listRubricsEndpoint(searchString, 15, 1)
    .then(async (res) => {
      rubric.options = res.rubrics.map((c) => {
        return {
          name: c.internal_label,
          value: c.rubric_id
        }
      })

      const taskRubric = (task?.currentEditing?.meta as ConversationTaskMeta).ConversationTask
        .rubric_id

      if (taskRubric) {
        const foundCurrentRubric = rubric.options.find((option) => option.value === taskRubric)
        if (!foundCurrentRubric) {
          const retrievedRubric = await Api.Content.getRubricEndpoint(taskRubric)

          if (retrievedRubric) {
            rubric.options.push({
              name: retrievedRubric.internal_label,
              value: retrievedRubric.rubric_id
            })
          }
        }
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      rubric.searchLoading = false
    })
}

// Task Characters
const character = reactive({
  options: [] as SelectOption[],
  search: false,
  searchLoading: false
})

const searchCharacters = (searchString = EMPTY_STRING_SEARCH_ALL) => {
  if (searchString === '') {
    searchString = EMPTY_STRING_SEARCH_ALL
  }
  character.searchLoading = true
  Api.Content.listCharactersEndpoint(searchString, 15, 1)
    .then(async (res) => {
      character.options = res.characters.map((c) => {
        return {
          name: c.internal_label,
          value: c.character_id
        }
      })

      const taskCharacter = (task?.currentEditing?.meta as ConversationTaskMeta).ConversationTask
        .character_id

      if (taskCharacter) {
        const foundCurrentCharacter = character.options.find(
          (option) => option.value === taskCharacter
        )
        if (!foundCurrentCharacter) {
          const retrievedCharacter = await Api.Content.getCharacterEndpoint(taskCharacter)

          if (retrievedCharacter) {
            character.options.push({
              name: retrievedCharacter.internal_label,
              value: retrievedCharacter.character_id
            })
          }
        }
      }
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      character.searchLoading = false
    })
}

// VR Task
// isVrTaskPredicate is a VR-free safe way to check a task for VR features
const isVrTask = computed(() => isVrTaskPredicate(task.currentEditing))

// task validation
const canSaveTask = computed(() => {
  return validateTasks([task.currentEditing as Task]).length
})

const validateTypeformLink = (link: string): boolean =>
  /^(https?):\/\/.+\.typeform\.com\/to\/([0-9a-zA-Z]*)([$?].+)?/.test(link)

const validateTasks = (tasks: Task[]): { index: number; fieldName: string; error: string }[] => {
  if (!tasks.length) {
    return []
  }
  let taskNames: string[] = []
  return tasks.reduce(
    (acc, item, index) => {
      if (!item.name.trim()) {
        acc.push({ index, fieldName: 'name', error: 'Missing value' })
      } else {
        // Validate duplicate task name
        if (taskNames.includes(item.name)) {
          acc.push({ index, fieldName: 'name', error: 'Duplicate task name' })
        } else {
          taskNames.push(item.name)
        }
      }
      if (item.task_type !== TaskTypeApiEnum.PRE_LEARNING_TASK && !item.description.trim()) {
        acc.push({ index, fieldName: 'description', error: 'Missing value' })
      }
      if (item.mandatory === undefined) {
        acc.push({ index, fieldName: 'mandatory', error: 'Missing value' })
      }

      if (item.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
        const { max_attempts, character_id, rubric_id, scene_id } = (
          item.meta as ConversationTaskMeta
        ).ConversationTask
        if (!max_attempts) {
          acc.push({ index, fieldName: 'max_attempts', error: 'Missing value' })
        } else if (max_attempts <= 0) {
          acc.push({ index, fieldName: 'max_attempts', error: 'Invalid value' })
        }

        if (!character_id) {
          acc.push({ index, fieldName: 'character_id', error: 'Missing value' })
        }
        if (!rubric_id) {
          acc.push({ index, fieldName: 'rubric_id', error: 'Missing value' })
        }

        if (isVrTask.value && !scene_id) {
          acc.push({ index, fieldName: 'scene_id', error: 'Missing value' })
        }
      } else if (item.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
        const { retryable, typeform_link } = (item.meta as FeedbackTaskMeta).FeedbackTask
        if (retryable == undefined) {
          acc.push({ index, fieldName: 'retryable', error: 'Missing value' })
        }

        if (!typeform_link) {
          acc.push({ index, fieldName: 'typeform_link', error: 'Missing value' })
        } else if (validateTypeformLink(typeform_link) === false) {
          acc.push({ index, fieldName: 'typeform_link', error: 'Invalid value' })
        }
      } else if (item.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK) {
        const emptySection = (
          item.meta as CreatePreLearningTaskMeta
        ).PreLearningTask.sections.filter((section) => section.trim() === '')

        if (emptySection.length) {
          acc.push({ index, fieldName: 'Section', error: 'Missing value' })
        }
      } else if (item.task_type === TaskTypeApiEnum.REFLECTION_TASK) {
        if (!reflectionTemplateSelected.value) {
          acc.push({ index, fieldName: 'Template', error: 'Missing value' })
        }

        const emptyQuestion = (
          item.meta as CreateReflectionTaskMeta
        ).ReflectionTask.questions.filter(
          (q) => q.question.trim() === '' || q.response_type === undefined
        )
        if (emptyQuestion.length) {
          acc.push({ index, fieldName: 'Question', error: 'Missing value' })
        }
      }

      return acc
    },
    [] as { index: number; fieldName: string; error: string }[]
  )
}

const popoverOptions = computed<PopoverOption[]>(() => {
  let conversationTaskOptions = {
    name: 'Conversation task',
    action: () => {
      /**
       * Use 'new-' with current time as task_id when editing assignment,
       * for new assignment use 'task-'
       */
      const newTaskId = assignment.currentEditing.assignment_id
        ? `new-${dayjs().valueOf()}`
        : `task-${dayjs().valueOf()}`

      const position = assignment.currentEditing.tasks.length
      const newConversationTask = {
        task_id: newTaskId,
        name: '',
        description: '',
        mandatory: false,
        position,
        task_type: TaskTypeApiEnum.CONVERSATION_TASK,
        meta: {
          ConversationTask: {
            max_attempts: 1,
            character_id: '',
            character_avatar_url: '',
            rubric_id: ''
          }
        }
      }
      task.currentEditing = newConversationTask
    }
  }
  let feedbackTaskOptions = {
    name: 'Feedback task',
    action: () => {
      /**
       * Use 'new-' with current time as task_id when editing assignment,
       * for new assignment use 'task-'
       */
      const newTaskId = assignment.currentEditing.assignment_id
        ? `new-${dayjs().valueOf()}`
        : `task-${dayjs().valueOf()}`

      const position = assignment.currentEditing.tasks.length
      const newFeedbackTask = {
        task_id: newTaskId,
        name: '',
        description: '',
        mandatory: false,
        position,
        task_type: TaskTypeApiEnum.FEEDBACK_TASK,
        meta: {
          FeedbackTask: {
            retryable: false,
            typeform_link: ''
          }
        }
      }
      task.currentEditing = newFeedbackTask
    }
  }
  let vrTaskOptions = {
    name: 'VR task',
    action: () => {
      /**
       * Use 'new-' with current time as task_id when editing assignment,
       * for new assignment use 'task-'
       */
      const newTaskId = assignment.currentEditing.assignment_id
        ? `new-${dayjs().valueOf()}`
        : `task-${dayjs().valueOf()}`

      const position = assignment.currentEditing.tasks.length

      // VR task is same as conversation task with scene field
      const newVRTask = {
        task_id: newTaskId,
        name: '',
        description: '',
        mandatory: false,
        position,
        task_type: TaskTypeApiEnum.CONVERSATION_TASK,
        meta: {
          ConversationTask: {
            max_attempts: 1,
            character_id: '',
            character_avatar_url: '',
            rubric_id: '',
            scene_id: ''
          }
        }
      }
      task.currentEditing = newVRTask
    }
  }
  let reflectionTaskOptions = {
    name: 'Reflection task',
    action: () => {
      /**
       * Use 'new-' with current time as task_id when editing assignment,
       * for new assignment use 'task-'
       */
      const newTaskId = assignment.currentEditing.assignment_id
        ? `new-${dayjs().valueOf()}`
        : `task-${dayjs().valueOf()}`

      const position = assignment.currentEditing.tasks.length
      const newReflectionTask = {
        task_id: newTaskId,
        name: '',
        description: '',
        mandatory: false,
        position,
        task_type: TaskTypeApiEnum.REFLECTION_TASK,
        meta: {
          ReflectionTask: {
            max_attempts: 1,
            questions: []
          }
        }
      }

      task.currentEditing = newReflectionTask
      reflectionTemplateSelected.value = false
    }
  }

  let prelearningTaskOptions = {
    name: 'Pre-Learning task',
    action: () => {
      /**
       * Use 'new-' with current time as task_id when editing assignment,
       * for new assignment use 'task-'
       */
      const newTaskId = assignment.currentEditing.assignment_id
        ? `new-${dayjs().valueOf()}`
        : `task-${dayjs().valueOf()}`

      const position = assignment.currentEditing.tasks.length
      const newPreLearningTask = {
        task_id: newTaskId,
        name: '',
        description: '',
        mandatory: false,
        position,
        task_type: TaskTypeApiEnum.PRE_LEARNING_TASK,
        meta: {
          PreLearningTask: {
            sections: ['']
          }
        }
      }

      task.currentEditing = newPreLearningTask
    }
  }

  let defaultTaskOptionsList = [
    conversationTaskOptions,
    prelearningTaskOptions,
    reflectionTaskOptions
  ]
  let superAdminTaskOptionsList = [feedbackTaskOptions, vrTaskOptions]
  if (authStore.isSuperAdminUser) {
    return [...defaultTaskOptionsList, ...superAdminTaskOptionsList]
  }
  return defaultTaskOptionsList
})

const addSection = () => {
  if (task?.currentEditing?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK) {
    ;(task.currentEditing.meta as CreatePreLearningTaskMeta).PreLearningTask.sections.push('')
    focusLatestRichTextInput()
  }
}

const addQuestion = () => {
  if (task?.currentEditing?.task_type === TaskTypeApiEnum.REFLECTION_TASK) {
    ;(task.currentEditing.meta as CreateReflectionTaskMeta).ReflectionTask.questions.push({
      question: '',
      response_type: ReflectionTaskResponseTypeApiEnum.LONG_TEXT,
      position: (task.currentEditing.meta as CreateReflectionTaskMeta).ReflectionTask.questions
        .length
    })
    focusLatestCustomInputText()
  }
}

// Use computed to proxy individual an property for use with v-model:
//  get()     ===   :model-value="..."
//  set(val)  ===   @update:model-value="(val) => ... = val"
const description = computed<string>({
  get: () => assignment.currentEditing.description,
  set: (content: string) => (assignment.currentEditing.description = content)
})
</script>

<template>
  <div class="sticky -top-3 z-50 flex flex-col gap-5 bg-white pt-3">
    <div
      class="flex cursor-pointer items-center gap-2 text-sc-grey-700"
      @click="
        () => {
          router.push({
            name: 'Cohort Assignments',
            params: { cohortId: assignmentStore.editingCohort.cohort_id }
          })
        }
      "
    >
      <ChevronLeftIcon class="h-4 w-4" />
      <h4 class="font-normal text-sc-grey-700">Back to Cohort</h4>
    </div>
    <div class="mb-5 flex w-full justify-between">
      <div :class="['flex items-center gap-3 overflow-hidden']">
        <h1 class="mr-5 truncate whitespace-nowrap text-2xl font-medium">
          {{
            route.params?.assignmentId === 'new'
              ? 'Create Assignment'
              : `${currentAssignment?.name}`
          }}
        </h1>
      </div>

      <div class="flex gap-3">
        <template v-if="!isNewAssignment">
          <CustomButton
            v-if="authStore.isSuperAdminUser"
            :loading="assignment.convertLoading"
            button-type="admin-secondary"
            @click="convertToActivity"
          >
            Convert to Activity
          </CustomButton>

          <CustomButton
            :loading="assignment.saveLoading"
            button-type="admin-secondary"
            @click="cloneAssignment"
          >
            Clone
          </CustomButton>

          <CustomButton
            :loading="assignment.deleteLoading"
            button-type="admin-secondary"
            @click="() => (assignment.deleteModalStatus = 1)"
          >
            Delete
          </CustomButton>
        </template>
        <CustomButton
          :disabled="canSave || task.loading"
          :loading="assignment.saveLoading"
          @click="isNewAssignment ? createAssignment() : saveEditAssignment()"
        >
          {{ isNewAssignment ? 'Create Assignment' : 'Save Changes' }}
        </CustomButton>
      </div>
    </div>
  </div>
  <div class="flex w-[80%] flex-col">
    <h2 class="mb-3 text-lg">Details</h2>
    <CustomInput v-model="assignment.currentEditing.name" class="my-0" label="Assignment name" />
    <div class="grid grid-cols-3 gap-x-5">
      <InputDatetimeLocal v-model="assignment.currentEditing.due_date" label="Due date" required />
      <CustomInput
        v-model="assignment.currentEditing.visible"
        input-type="select"
        label="Visibility"
        :options="[
          {
            name: 'Visible to students',
            value: true
          },
          {
            name: 'Hidden to students',
            value: false
          }
        ]"
      />
      <CustomInput
        v-model="assignment.currentEditing.purpose"
        input-type="select"
        label="Assignment Type"
        :required="false"
        :options="[
          {
            name: '',
            value: AssignmentPurposeApiEnum.NOTHING
          },
          {
            name: 'Formative',
            value: AssignmentPurposeApiEnum.FORMATIVE
          },
          {
            name: 'Summative',
            value: AssignmentPurposeApiEnum.SUMMATIVE
          }
        ]"
      />
    </div>
    <InputRichText
      id="description-editor"
      v-model="description"
      :required="true"
      label="Description"
      :rows="12"
    />
  </div>

  <template v-if="!isNewAssignment">
    <div class="h-px w-[90%] bg-sc-grey-200" />

    <div class="mb-5 flex w-[80%] flex-col gap-5">
      <h2 class="basis-auto text-lg">
        Tasks
        <span v-if="!task.loading" class="text-sc-grey-600">{{
          `(${assignment.currentEditing.tasks.length})`
        }}</span>
      </h2>
      <AppLoadingSpinner v-if="task.loading" class="py-20" loading />
      <p v-else-if="!assignment.currentEditing.tasks.length" class="py-5 text-sc-grey-600">
        You have not yet added any tasks to this assignment
      </p>

      <DraggableTemplate
        v-else
        class="!pb-0"
        :library-view="false"
        :draggable-items="assignment.currentEditing.tasks"
        :can-delete="true"
        unique-id="rubric_sections"
        @on-delete="
          (index) => {
            task.deleteModalStatus = index
          }
        "
        @change-draggable-items="changeTasks"
        @on-view="(task) => toTask(task)"
      >
        <template #customEndItem>
          <small class="text-sc-grey-600">Click to view Task</small>
        </template>
      </DraggableTemplate>
    </div>

    <CustomPopover
      v-if="!task.loading"
      class="[&_.popover-position]:-top-[calc((var(--task-type-popover-length)+1)*40px)] [&_.popover-position]:mt-2"
      :style="{ '--task-type-popover-length': popoverOptions.length }"
      button-type="admin-secondary"
      :start-icon="PlusIcon"
      :popover-options="popoverOptions"
    >
      Add task
    </CustomPopover>
  </template>

  <CustomModal
    :model-value="!!task?.currentEditing?.task_id"
    class="[&_.dialog-panel]:min-h-[780px] [&_.dialog-panel]:w-[699px]"
  >
    <div v-if="task.currentEditing" class="flex flex-1 flex-col overflow-y-hidden">
      <div
        ref="tasksContainer"
        class="mt-2 flex flex-1 flex-col divide-y-2 divide-dashed overflow-y-auto"
      >
        <div :class="['group relative flex flex-col gap-5 px-1 pb-1 pt-5 first:pt-1']">
          <div
            class="invisible absolute bottom-3 left-0 right-0 top-3 rounded-mlg bg-sc-grey-100 group-first:top-0"
          />
          <h3 class="sticky -top-3 z-50 -mb-5 bg-white pb-5 pt-3 text-lg">
            {{ taskName }}
          </h3>

          <VRTaskForm v-if="isVrTask" :task="task.currentEditing" />
          <ConversationTaskForm
            v-else-if="task?.currentEditing?.task_type === TaskTypeApiEnum.CONVERSATION_TASK"
            :task="task.currentEditing"
            :rubric-options="rubric.options"
            :character-options="character.options"
            :character-search-loading="character.searchLoading"
            :rubric-search-loading="rubric.searchLoading"
            @rubric-search="searchRubrics"
            @character-search="searchCharacters"
          />
          <FeedbackTaskForm
            v-else-if="task?.currentEditing?.task_type === TaskTypeApiEnum.FEEDBACK_TASK"
            :task="task.currentEditing"
          />
          <PreLearningTaskForm
            v-else-if="task?.currentEditing?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK"
            :task="task.currentEditing"
          />
          <ReflectionTaskForm
            v-else-if="task?.currentEditing?.task_type === TaskTypeApiEnum.REFLECTION_TASK"
            :task="task.currentEditing"
            :show-template="true"
            @template-selected="reflectionTemplateSelected = true"
          />
        </div>
      </div>
      <div
        :class="[
          'mt-5 flex basis-auto gap-5',
          task?.currentEditing?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK ||
          (task?.currentEditing?.task_type === TaskTypeApiEnum.REFLECTION_TASK &&
            reflectionTemplateSelected)
            ? 'justify-between'
            : 'justify-end'
        ]"
      >
        <div v-if="task?.currentEditing?.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK">
          <CustomButton :end-icon="PlusIcon" button-type="admin-secondary" @click="addSection">
            Add Section
          </CustomButton>
        </div>
        <div
          v-else-if="
            task?.currentEditing?.task_type === TaskTypeApiEnum.REFLECTION_TASK &&
            reflectionTemplateSelected
          "
        >
          <CustomButton :end-icon="PlusIcon" button-type="admin-secondary" @click="addQuestion">
            Add Question
          </CustomButton>
        </div>
        <div class="flex gap-5">
          <CustomButton button-type="admin-secondary" @click="closeModal"> Cancel </CustomButton>
          <CustomButton
            button-type="admin-primary"
            :disabled="!!canSaveTask"
            :loading="task.saveLoading"
            @click="saveTask"
          >
            Create Task
          </CustomButton>
        </div>
      </div>
    </div>
  </CustomModal>

  <CustomDeleteModal
    :modal-status="task.deleteModalStatus !== -1 || assignment.deleteModalStatus !== -1"
    :title="`Delete ${task.deleteModalStatus !== -1 ? 'Task' : 'Assignment'}`"
    :message="` Are you sure you want to delete this ${
      task.deleteModalStatus !== -1 ? 'task' : 'assignment'
    }? This cannot be undone.`"
    @confirm="task.deleteModalStatus !== -1 ? deleteTask() : deleteAssignment()"
    @cancel="
      () => {
        task.deleteModalStatus = -1
        assignment.deleteModalStatus = -1
      }
    "
  />
</template>
