import type { AddEducatorToCohortInput } from '../models/AddEducatorToCohortInput';
import type { AddStudentToCohortInput } from '../models/AddStudentToCohortInput';
import type { CohortRoleApi } from '../models/CohortRoleApi';
import type { CreateCohortInputV2 } from '../models/CreateCohortInputV2';
import type { GetCohortEducatorsOutput } from '../models/GetCohortEducatorsOutput';
import type { GetCohortOutputV2 } from '../models/GetCohortOutputV2';
import type { GetCohortStudentsOutput } from '../models/GetCohortStudentsOutput';
import type { GetMyCohortsOutput } from '../models/GetMyCohortsOutput';
import type { GetOrganizationCohortsOutput } from '../models/GetOrganizationCohortsOutput';
import type { RemoveEducatorFromCohortInput } from '../models/RemoveEducatorFromCohortInput';
import type { RemoveStudentFromCohortInputV2 } from '../models/RemoveStudentFromCohortInputV2';
import type { SearchCohortUsersOutput } from '../models/SearchCohortUsersOutput';
import type { UpdateCohortInputV2 } from '../models/UpdateCohortInputV2';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';


export class CohortService {

	/**
	 * AddEducatorToCohort
	 * Adds an educator to a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static addEducatorToCohortEndpoint(
requestBody: AddEducatorToCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/AddEducatorToCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * AddStudentToCohort
	 * Adds a student to a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static addStudentToCohortEndpoint(
requestBody: AddStudentToCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/AddStudentToCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * CreateCohort
	 * Creates a new cohort.
	 * @param requestBody 
	 * @returns any Create a new cohort.
	 * @throws ApiError
	 */
	public static createCohortEndpointV2(
requestBody: CreateCohortInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/CreateCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohort
	 * Retrieve a cohort by ID.
	 * @param cohortId The ID of the cohort to retrieve.
	 * @returns GetCohortOutputV2 Cohort retrieved successfully.
	 * @throws ApiError
	 */
	public static getCohortEndpointV2(
cohortId: string,
): CancelablePromise<GetCohortOutputV2> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetCohort',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this cohort.`,
			},
		});
	}

	/**
	 * GetCohortEducators
	 * @param cohortId The ID of the cohort to get educators for.
	 * @returns GetCohortEducatorsOutput Success.
	 * @throws ApiError
	 */
	public static getCohortEducatorsEndpoint(
cohortId: string,
): CancelablePromise<GetCohortEducatorsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetCohortEducators',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohortStudents
	 * @param cohortId The ID of the cohort to get students for.
	 * @returns GetCohortStudentsOutput Success.
	 * @throws ApiError
	 */
	public static getCohortStudentsEndpoint(
cohortId: string,
): CancelablePromise<GetCohortStudentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetCohortStudents',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetMyCohorts
	 * @returns GetMyCohortsOutput Success.
	 * @throws ApiError
	 */
	public static getMyCohortsEndpointV2(): CancelablePromise<GetMyCohortsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetMyCohorts',
			errors: {
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetOrganizationCohorts
	 * @param organizationId The ID of the organization to get cohorts for.
	 * @returns GetOrganizationCohortsOutput Success.
	 * @throws ApiError
	 */
	public static getOrganizationCohortsEndpoint(
organizationId: string,
): CancelablePromise<GetOrganizationCohortsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetOrganizationCohorts',
			query: {
				organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * RemoveEducatorFromCohort
	 * Remove an educator from a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static removeEducatorFromCohortEndpoint(
requestBody: RemoveEducatorFromCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/RemoveEducatorFromCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * RemoveStudentFromCohort
	 * Remove a student from a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static removeStudentFromCohortEndpointV2(
requestBody: RemoveStudentFromCohortInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/RemoveStudentFromCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SearchCohortUsers
	 * @param cohortId The ID of the cohort to search.
	 * @param query The search query for the user's email.
	 * @param roleFilter The kind of user to search for.
	 * @param limit The maximum number of search results.
	 * @param cursor If `null`, returns the first page.
 * 
 * If non-null, returns the page corresponding to this cursor. See the
 * `next_page` field in the response.
	 * @returns SearchCohortUsersOutput Success.
	 * @throws ApiError
	 */
	public static searchCohortUsersEndpoint(
cohortId: string,
query: string,
roleFilter: CohortRoleApi,
limit: number,
cursor?: string | null,
): CancelablePromise<SearchCohortUsersOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/SearchCohortUsers',
			query: {
				cohort_id: cohortId, query, role_filter: roleFilter, limit, cursor
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateCohort
	 * Updates an existing cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static updateCohortEndpointV2(
requestBody: UpdateCohortInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/UpdateCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

}