<script setup lang="ts">
import { ref, computed, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyAssignmentInstance from '@/composables/api/queries/useGetMyAssignmentInstance'
import useEnterTaskAttempt from '@/composables/api/useEnterTaskAttempt'
import { CommandState } from '@/composables/api/mutations/types'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { Activity } from '@/components/modern/activities-tasks-view'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern - Learner Activity View',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Activity')

const route = useRoute('Modern - Learner Activity View')
const assignmentInstanceId = computed(() => route.params.activityId)

const inTaskView = ref<boolean>(false)

const taskId = ref<string | null>(null)
const selectTask = (id: string) => {
  inTaskView.value = true
  taskId.value = id
}
const deselectTask = () => {
  inTaskView.value = false
  taskId.value = null
}

const notificationStore = useNotificationStore()
const { assignmentInstance } = useGetMyAssignmentInstance({
  assignmentInstanceId,
  notificationStore
})
const task = computed(
  () => assignmentInstance.value?.tasks.find((task) => task.task_id === taskId.value) ?? null
)
const hasIncompleteAttempts = computed(
  () => task.value?.attempts.some((a) => !a.completed_at) ?? false
)

const { state, execute, reset } = useEnterTaskAttempt({
  assignmentInstanceId,
  task,
  notificationStore
})

const router = useRouter()

const startTask = async () => {
  const params = await execute()
  if (state.value === CommandState.SUCCESS && params) {
    reset()
    router.push({
      name: 'Modern - Attempt Task or Review Task Attempt',
      params
    })
  } else {
    reset()
  }
}

const startTaskDirectly = (id: string) => {
  taskId.value = id
  nextTick(() => {
    startTask()
  })
}

useScrollToTopOnChange(taskId)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton v-if="task" name="activity" @click="deselectTask" />
      <BackButton v-else :to="{ name: 'Modern - My Activities' }" name="my activities" />
    </template>
    <template v-if="task && inTaskView" #right>
      <Button
        v-if="hasIncompleteAttempts"
        variant="default"
        size="xs"
        class="w-min"
        @click="startTask"
      >
        <PlayIcon class="mr-2 size-4" />
        <span>Continue Task</span>
      </Button>
      <Button
        v-else-if="task.attempts.length && task.attempts.length < task.max_attempts"
        variant="default"
        size="xs"
        class="w-min"
        @click="startTask"
      >
        <PlayIcon class="mr-2 size-4" />
        <span>Retry Task</span>
      </Button>
      <Button
        v-else-if="task.attempts.length < task.max_attempts"
        variant="default"
        size="xs"
        class="w-min"
        @click="startTask"
      >
        <PlayIcon class="mr-2 size-4" />
        <span>Start Task</span>
      </Button>
    </template>
  </TopLine>
  <Activity
    :assignment-instance="assignmentInstance"
    :selected-task="task"
    :in-task-view="inTaskView"
    @select-task="selectTask"
    @start-task="startTaskDirectly"
  />
</template>
