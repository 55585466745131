<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useSearchOrganizations from '@/composables/api/queries/useSearchOrganizations'
import useGetAssignmentAccess from '@/composables/api/queries/useGetAssignmentAccess'
import useUpdateAssignmentAccess from '@/composables/api/mutations/useUpdateAssignmentAccess'
import { CommandState } from '@/composables/api/mutations/types'
import type { OrganizationIdAndName } from '@/open-api/generated'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/access-list'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { Label } from '@/components/modern/ui/label'
import { Button } from '@/components/modern/ui/button'
import { Separator } from '@/components/modern/ui/separator'
import { CheckboxIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Activities - Activity Access List',
  meta: {
    permissionLevel: 'Staff',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Activity Access List')

const route = useRoute('Modern Activities - Activity Access List')
const assignmentId = computed<string>(() => route.params.activityId)
const notificationStore = useNotificationStore()

const { owningOrganization, accessList, refetch, isLoading } = useGetAssignmentAccess({
  assignmentId,
  notificationStore
})

const updatedOwningOrg = ref<string>()
watch(
  owningOrganization,
  () => (updatedOwningOrg.value = owningOrganization.value?.organization_id),
  { immediate: true }
)

const updatedAccessList = ref<OrganizationIdAndName[]>([])
watch(accessList, () => (updatedAccessList.value = accessList.value ?? []), { immediate: true })

const removeFromAccessList = (org: OrganizationIdAndName) =>
  (updatedAccessList.value = updatedAccessList.value.filter(
    (o) => o.organization_id !== org.organization_id
  ))
const addToAccessList = (org: OrganizationIdAndName) => {
  if (updatedAccessList.value.every((o) => o.organization_id !== org.organization_id)) {
    // Don't add duplicates
    updatedAccessList.value = [...updatedAccessList.value, org]
  }
}

// Set up the mutations
const { state, execute, reset } = useUpdateAssignmentAccess({ assignmentId, notificationStore })

const submitOwningOrg = async () => {
  if (!accessList.value || !updatedOwningOrg.value) {
    return
  }

  await execute(
    accessList.value.map((org) => org.organization_id),
    updatedOwningOrg.value
  )
  if (state.value === CommandState.SUCCESS) {
    refetch()
  }
  reset()
}

const submitAccessList = async () => {
  if (!updatedAccessList.value || !owningOrganization.value) {
    return
  }

  await execute(
    updatedAccessList.value.map((org) => org.organization_id),
    owningOrganization.value.organization_id
  )
  if (state.value === CommandState.SUCCESS) {
    refetch()
  }
  reset()
}

// Set up the SearchSelect boxen
const owningSearchQuery = ref<string>('')
const { organizations: owningSearchOrganizations, isLoading: owningSearchLoading } =
  useSearchOrganizations({
    query: owningSearchQuery,
    notificationStore
  })

const accessSearchQuery = ref<string>('')
const { organizations: accessSearchOrganizations, isLoading: accessSearchLoading } =
  useSearchOrganizations({
    query: accessSearchQuery,
    notificationStore
  })

const columns = useColumns({ removeFromAccessList })

// TODO: sync global content / sync to other regions?
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Modern Activities - All Activities List' }" name="all activities" />
    </template>
  </TopLine>
  <div class="m-4 w-auto max-w-4xl space-y-2 lg:m-6">
    <Label for="owning-organization">Owning Organization</Label>
    <SearchSelect
      id="owning-organization"
      v-model:query="owningSearchQuery"
      v-model:selected-value="updatedOwningOrg"
      :data="owningSearchOrganizations"
      :loading="owningSearchLoading"
      value-key="organization_id"
      label-key="name"
      placeholder-label="owning organization"
    />
    <Button variant="default" size="xs" class="!mt-4" @click="submitOwningOrg">
      <CheckboxIcon class="mr-2 size-4" />
      <span>Save changes</span>
    </Button>
  </div>
  <div class="mx-4 mb-2 mt-8 w-auto max-w-4xl lg:mx-6">
    <Label for="organization-access">Organizations with access</Label>
  </div>
  <Separator />
  <DataTable
    :data="updatedAccessList"
    :columns="columns"
    :loading="isLoading"
    empty="No other organizations have access to this activity."
  />
  <div class="mx-4 mt-4 w-auto max-w-4xl space-y-4 lg:mx-6">
    <SearchSelect
      id="organization-access"
      v-model:query="accessSearchQuery"
      :data="accessSearchOrganizations"
      :loading="accessSearchLoading"
      value-key="organization_id"
      label-key="name"
      placeholder-label="organization to add"
      placeholder-label-plural="organizations"
      clear-on-select
      @select-element="addToAccessList"
    />

    <Button variant="default" size="xs" class="flex" @click="submitAccessList">
      <CheckboxIcon class="mr-2 size-4" />
      <span>Save changes</span>
    </Button>
  </div>
</template>
