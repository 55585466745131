<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import useSearchOrganizationAssignments from '@/composables/api/queries/useSearchOrganizationAssignments'
import generateResourceId from '@/utils/generateResourceId'
import useAssignToCohort from '@/composables/api/mutations/useAssignToCohort'
import { schema, type Schema } from '@/composables/api/mutations/schema/useAssignToCohort'
import { CommandState } from '@/composables/api/mutations/types'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import {
  LABEL_ASSIGN_OPENDATE,
  LABEL_ASSIGN_DUEDATE,
  DESCRIPTION_ASSIGN_OPENDATE,
  DESCRIPTION_ASSIGN_DUEDATE
} from '@/constants/forms'
import { Button } from '@/components/modern/ui/button'
import { Label } from '@/components/modern/ui/label'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { PlusCircledIcon } from '@radix-icons/vue'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'Modern Cohorts - Assign Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Assign Activity')

// Get the relevant route params
const route = useRoute('Modern Cohorts - Assign Activity')
const cohortId = computed(() => route.params.cohortId)

// Assertion that organizationId is defined is safe because we are in a guarded route
const authStore = useAuthStore()
const organizationId = computed(() => authStore.organizationId!)

const router = useRouter()
const notificationStore = useNotificationStore()

// Set up SearchSelect for selecting an assignment
const assignmentId = ref<string>('')
const query = ref<string>('')
const { isLoading, assignments } = useSearchOrganizationAssignments({
  organizationId,
  query,
  notificationStore
})

// Set up fresh assignmentInstanceId
const assignmentInstanceId = ref<string>(generateResourceId('assignment_instance_id'))
const { state, execute, reset } = useAssignToCohort({
  assignmentId,
  cohortId,
  assignmentInstanceId,
  notificationStore
})

const form = useForm({ validationSchema: toTypedSchema(schema) })

const fieldConfig: Config<Schema> = {
  openDate: {
    label: LABEL_ASSIGN_OPENDATE,
    description: DESCRIPTION_ASSIGN_OPENDATE
  },
  dueDate: {
    label: LABEL_ASSIGN_DUEDATE,
    description: DESCRIPTION_ASSIGN_DUEDATE
  }
}

const submit = form.handleSubmit(async (values: Schema) => {
  await execute(values)
  if (state.value === CommandState.FAILURE) {
    reset()
    assignmentInstanceId.value = generateResourceId('assignment_instance_id')
  } else if (state.value === CommandState.SUCCESS) {
    reset()
    router.push({
      name: 'Modern Cohorts - Cohort Activities List',
      params: { cohortId: cohortId.value }
    })
  }
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
        name="cohort"
      />
    </template>
    <template #right>
      <Button v-if="assignmentId" variant="default" size="xs" @click="submit">
        <PlusCircledIcon class="mr-1 size-4" />
        <span>Assign</span>
      </Button>
    </template>
  </TopLine>
  <div class="mt-4 flex w-full flex-row justify-center lg:mt-6">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <Label for="search-assignments" class="mb-2 block">Search for an activity</Label>
      <SearchSelect
        id="search-assignments"
        v-model:query="query"
        v-model:selected-value="assignmentId"
        :data="assignments"
        value-key="assignment_id"
        label-key="name"
        placeholder-label="activity"
        placeholder-label-plural="activities"
        :loading="isLoading"
      />
    </div>
  </div>

  <div class="flex w-full flex-row justify-center">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <AutoForm
        v-if="assignmentId"
        :schema="schema"
        :form="form"
        :field-config="fieldConfig"
        class="flex-1 grow"
      >
        <Button variant="default" size="xs" @click="submit">
          <PlusCircledIcon class="mr-1 size-4" />
          <span>Assign</span>
        </Button>
      </AutoForm>
    </div>
  </div>
</template>
