<script setup lang="ts">
import { type HTMLAttributes, computed, useTemplateRef, provide } from 'vue'
import {
  ScrollAreaCorner,
  ScrollAreaRoot,
  type ScrollAreaRootProps,
  ScrollAreaViewport
} from 'radix-vue'
import ScrollBar from './ScrollBar.vue'
import { cn } from '@/lib/utils'
import { scrollToTopKey } from './index'

const props = defineProps<ScrollAreaRootProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

// Get the scrollTop method from radix-vue's ScrollArea using a template ref:
// https://www.radix-vue.com/components/scroll-area.html#root
// https://vuejs.org/api/composition-api-helpers.html#usetemplateref
const scrollAreaRootRef = useTemplateRef('scrollAreaRoot')
const scrollToTop = () => scrollAreaRootRef.value?.scrollTop()
// Make it available to anyone inside this scroll area
// https://vuejs.org/guide/components/provide-inject.html#provide
provide(scrollToTopKey, scrollToTop)
</script>

<template>
  <ScrollAreaRoot
    ref="scrollAreaRoot"
    v-bind="delegatedProps"
    :class="cn('relative overflow-hidden', props.class)"
  >
    <ScrollAreaViewport class="h-full w-full rounded-[inherit]">
      <slot />
    </ScrollAreaViewport>
    <ScrollBar />
    <ScrollAreaCorner />
  </ScrollAreaRoot>
</template>
