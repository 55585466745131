<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import generateResourceUuid from '@/utils/generateResourceId'
import useCreateAssignmentV2 from '@/composables/api/mutations/useCreateAssignmentV2'
import { schema, type Schema } from '@/composables/api/mutations/schema/useCreateAssignmentV2'
import { CommandState } from '@/composables/api/mutations/types'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import {
  LABEL_ACTIVITY_NAME,
  LABEL_ACTIVITY_DESCRIPTION,
  DESCRIPTION_ACTIVITY_NAME,
  DESCRIPTION_ACTIVITY_DESCRIPTION
} from '@/constants/forms'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { useAuthStore } from '@/stores/auth'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Activities - New Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})
const router = useRouter()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('New Activity')

const assignmentId = ref<string>(generateResourceUuid('assignment_id'))

const { state, execute, reset } = useCreateAssignmentV2({
  assignmentId,
  notificationStore,
  authStore
})

const form = useForm({ validationSchema: toTypedSchema(schema) })

const fieldConfig: Config<Schema> = {
  name: {
    label: LABEL_ACTIVITY_NAME,
    description: DESCRIPTION_ACTIVITY_NAME
  },
  description: {
    component: 'textarea',
    label: LABEL_ACTIVITY_DESCRIPTION,
    description: DESCRIPTION_ACTIVITY_DESCRIPTION
  }
}

const submit = form.handleSubmit(async (values: Schema) => {
  await execute({ form: values })
  if (state.value === CommandState.FAILURE) {
    reset()
    assignmentId.value = generateResourceUuid('assignment_id')
  } else if (state.value === CommandState.SUCCESS) {
    reset()
    router.push({
      name: 'Modern Activities - Edit Activity',
      params: { activityId: assignmentId.value }
    })
  }
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Modern Activities - All Activities List' }" name="all activities" />
    </template>
    <template #right>
      <Button variant="default" size="xs" @click="submit">
        <PlusIcon class="mr-2 size-4" />
        <span>Create</span>
      </Button>
    </template>
  </TopLine>
  <div class="mx-4 w-auto max-w-4xl self-center lg:mx-6">
    <AutoForm :schema="schema" :form="form" :field-config="fieldConfig" class="mt-4">
      <Button variant="default" size="xs" @click="submit">
        <PlusIcon class="mr-2 size-4" />
        <span>Create</span>
      </Button>
    </AutoForm>
  </div>
</template>
