<script setup lang="ts">
import type { CreateRubricInput, GetRubricInput, RubricOutput } from '@/open-api/generated'
import { DocumentDuplicateIcon, LockOpenIcon, TrashIcon, PlayIcon } from '@heroicons/vue/24/outline'
import { onBeforeMount, ref, computed } from 'vue'
import { useContentStore } from '@/stores/content'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import useSearchCharacters from '@/composables/api/queries/useSearchCharacters'
import useStartConversation from '@/composables/api/mutations/useStartConversation'
import { CommandState } from '@/composables/api/mutations/types'

definePage({
  name: 'Rubric',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute<'Rubric' | 'Rubric View' | 'Rubric Access Controls'>()
const router = useRouter()
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.rubricId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingRubric as RubricOutput)?.owning_organization_id
    )
  )
})

const rubricLoading = ref(false)
const saveLoading = ref(false)

const { state, execute, reset } = useStartConversation({ notificationStore })
const previewLoading = computed((): boolean => state.value === CommandState.IN_PROGRESS)

const deleteRubricModalStatus = ref(false)
const deleteRubricLoading = ref(false)

onBeforeMount(async () => {
  setCurrentRubric()
})

// ==================================================
// Rubric
// ==================================================
const rubricNotComplete = computed(() => {
  const { public_label, internal_description, internal_label, minimum_passing_score } =
    contentStore.editingRubric
  return (
    !public_label?.trim() ||
    !internal_description?.trim() ||
    !internal_label?.trim() ||
    !minimum_passing_score ||
    minimum_passing_score <= 0
  )
})

const setCurrentRubric = async () => {
  if (route.params.rubricId === 'new') {
    contentStore.setEditingRubric(contentStore.newRubric)
  } else if ((contentStore.editingRubric as RubricOutput)?.rubric_id !== route.params.rubricId) {
    rubricLoading.value = true
    try {
      const rubric = await Api.Content.getRubricEndpoint(route.params.rubricId as string)

      if (!rubric) {
        router.push({ name: 'Rubrics' })
      }

      contentStore.setEditingRubric(rubric as GetRubricInput)
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      rubricLoading.value = false
    }
  }
}

// ==================================================
// Create Rubric
// ==================================================
const createRubric = () => {
  saveLoading.value = true

  Api.Content.createRubricEndpoint(contentStore.editingRubric as CreateRubricInput)
    .then((res) => {
      notificationStore.addNotification({
        subtitle: 'Rubric successfully created',
        status: NotificationStatus.SUCCESS
      })
      router
        .push({
          name: route.name,
          params: { rubricId: res.rubric_id }
        })
        .then(async () => {
          await setCurrentRubric()
        })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
// Duplicate Rubric
// ==================================================
const duplicateRubricLoading = ref(false)
const duplicateRubric = async () => {
  duplicateRubricLoading.value = true

  const editingRubric = contentStore.editingRubric as RubricOutput
  const duplicatedRubric: CreateRubricInput = {
    public_label: `Copy of ${editingRubric.public_label}`,
    internal_label: `Copy of ${editingRubric.internal_label} ${dayjs().format(
      'DD/MM/YYYY HH:mm:ss'
    )}`,
    internal_description: editingRubric.internal_description,
    minimum_passing_score: editingRubric.minimum_passing_score,
    sections: editingRubric.sections,
    allowlist: editingRubric.allowlist?.map((org) => org.organization_id) || []
  }

  await Api.Content.createRubricEndpoint(duplicatedRubric)
    .then(() => {
      notificationStore.addNotification({
        subtitle: `${editingRubric.public_label} successfully cloned`,
        status: NotificationStatus.SUCCESS
      })

      router.push({
        name: 'Rubrics'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      duplicateRubricLoading.value = false
    })
}

// ==================================================
// Delete Rubric
// ==================================================
const confirmDeleteRubric = () => {
  deleteRubricLoading.value = true
  Api.Content.deleteRubricEndpoint({
    rubric_id: route.params.rubricId as string
  })
    .then(() => {
      deleteRubricModalStatus.value = false

      router.push({
        name: 'Rubrics'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      deleteRubricLoading.value = false
    })
}

// ==================================================
//  Simulate Rubric
// ==================================================
const previewCharacterId = ref('')
const characterSelectModalOpen = ref(false)
const query = ref('')
const { characters: characterSearchResults, isLoading: isCharacterSearchLoading } =
  useSearchCharacters({ query, notificationStore })

const onPreviewCharacterSearch = (searchString: string) => {
  // Need to do this because custom input clears the search string on selection
  if (searchString) {
    query.value = searchString
  }
}
const previewRubric = async () => {
  if (!('rubric_id' in contentStore.editingRubric && contentStore.editingRubric.rubric_id)) {
    notificationStore.addNotification({
      subtitle: 'You need to save the rubric first.',
      status: NotificationStatus.WARNING
    })
    return
  }
  const rubricId = ref<string>(contentStore.editingRubric.rubric_id)

  if (!previewCharacterId.value) {
    notificationStore.addNotification({
      subtitle: 'You need to select a character first.',
      status: NotificationStatus.DANGER
    })
    return
  }

  const conversationId = await execute({
    rubricId,
    characterId: previewCharacterId
  })

  if (state.value === CommandState.SUCCESS && conversationId) {
    reset()
    router.push({
      name: 'Cohort Conversation',
      params: { conversationId }
    })
  } else {
    notificationStore.addDANGER('Sorry, there was an error trying to start the conversation.')
    reset()
  }
}
</script>

<template>
  <div :class="['flex min-h-full flex-col gap-y-5']">
    <div class="sticky -top-3 z-50 mb-5 flex justify-between bg-white pt-3">
      <div class="flex flex-row items-center">
        <h1 class="mr-5 truncate pr-10 text-2xl font-medium">
          {{
            route.query?.mode === 'create'
              ? 'Create Rubric'
              : contentStore.editingRubric.public_label
          }}
        </h1>
        <CustomButton v-if="isReadOnly" button-type="grey" button-size="sm" :can-select="false">
          READ-ONLY
        </CustomButton>
      </div>

      <div class="flex gap-3">
        <CustomButton
          v-if="route.name === 'Rubric Access Controls'"
          button-type="admin-secondary"
          @click="router.push({ name: 'Rubric View' })"
        >
          Back to Rubric
        </CustomButton>
        <CustomButton
          v-if="route.params.rubricId === 'new'"
          :disabled="rubricNotComplete"
          :loading="saveLoading"
          button-type="admin-primary"
          @click="createRubric"
        >
          Create Rubric
        </CustomButton>
        <CustomButton
          v-if="
            route.params.rubricId !== 'new' &&
            route.name !== 'Rubric Access Controls' &&
            !isReadOnly
          "
          button-type="admin-secondary"
          :start-icon="PlayIcon"
          :loading="previewLoading"
          @click="characterSelectModalOpen = true"
        >
          Preview Rubric
        </CustomButton>
        <CustomButton
          v-if="
            route.params.rubricId !== 'new' &&
            route.name !== 'Rubric Access Controls' &&
            !isReadOnly
          "
          :disabled="duplicateRubricLoading"
          button-type="admin-secondary"
          :start-icon="DocumentDuplicateIcon"
          @click="duplicateRubric"
        />
        <CustomButton
          v-if="
            route.params.rubricId !== 'new' &&
            authStore.isAtLeastStaffUser &&
            route.name !== 'Rubric Access Controls' &&
            !isReadOnly
          "
          button-type="admin-secondary"
          :start-icon="LockOpenIcon"
          @click="router.push({ name: 'Rubric Access Controls' })"
        />
        <CustomButton
          v-if="
            route.params.rubricId !== 'new' &&
            route.name !== 'Rubric Access Controls' &&
            !isReadOnly
          "
          button-type="admin-secondary"
          :start-icon="TrashIcon"
          @click="deleteRubricModalStatus = true"
        />
      </div>
    </div>

    <div v-if="rubricLoading" class="flex h-full w-full items-center justify-center">
      <AppLoadingSpinner class="py-20" :loading="rubricLoading" />
    </div>
    <router-view v-else />
  </div>

  <!-- Delete modal -->
  <CustomDeleteModal
    title="Delete Rubric"
    message="Are you sure you want to delete this rubric? This cannot be undone."
    :modal-status="deleteRubricModalStatus"
    :loading="deleteRubricLoading"
    @confirm="confirmDeleteRubric"
    @cancel="deleteRubricModalStatus = false"
  />
  <!-- Preview Character Selection Modal -->
  <CustomModal v-model="characterSelectModalOpen" @on-close="characterSelectModalOpen = false">
    <div class="flex w-full flex-col gap-5">
      <h3 class="basis-auto text-xl">Select a character to test with</h3>
      <div class="min-w-full">
        <CustomInput
          v-model="previewCharacterId"
          input-type="select-search"
          :options="
            characterSearchResults.map((res) => ({
              name: res.internal_label,
              value: res.character_id
            }))
          "
          label="Select Character"
          :loading="isCharacterSearchLoading"
          @search="onPreviewCharacterSearch"
        />
      </div>
      <div class="flex justify-between gap-5">
        <CustomButton
          button-type="admin-secondary"
          class="basis-auto self-end"
          @click="characterSelectModalOpen = false"
        >
          Cancel
        </CustomButton>

        <CustomButton
          :start-icon="PlayIcon"
          button-type="admin-primary"
          class="basis-auto self-end"
          @click="previewRubric"
        >
          Preview Rubric
        </CustomButton>
      </div>
    </div>
  </CustomModal>
</template>
