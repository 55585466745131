<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetCohortAssignmentInsights from '@/composables/api/queries/useGetCohortAssignmentInsights'
import { type CohortAssignmentInsight } from '@/open-api/generated'
import useVoidAssignmentInstance from '@/composables/api/mutations/useVoidAssignmentInstance'
import { CommandState } from '@/composables/api/mutations/types'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/cohort-activities'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Tabs, TabsList, TabsTrigger } from '@/components/modern/ui/tabs'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Activities List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohort Activities')

const route = useRoute('Modern Cohorts - Cohort Activities List')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()

const notificationStore = useNotificationStore()

const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isLoading,
  assignments,
  refetch
} = useGetCohortAssignmentInsights({
  cohortId,
  notificationStore
})

const assignmentInstanceId = ref<string>('')
const { execute, state, reset } = useVoidAssignmentInstance({
  assignmentInstanceId,
  notificationStore
})
const requestVoidInstance = ({ assignment_instance_id }: CohortAssignmentInsight) => {
  assignmentInstanceId.value = assignment_instance_id
}
const confirmVoidInstance = async () => {
  await execute()
  if (state.value == CommandState.SUCCESS) {
    refetch()
  }
  reset()
}

const columns = useColumns({ cohortId, requestVoidInstance, confirmVoidInstance })
const groups = useGroups()
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        v-if="authStore.isAtLeastInstitutionAdminUser"
        :to="{ name: 'Modern Cohorts - Cohort Administration' }"
        name="cohort administration"
      />
      <BackButton v-else :to="{ name: 'Modern Cohorts - My Cohorts List' }" name="all cohorts" />
    </template>
    <template #center>
      <Tabs default-value="activities">
        <TabsList class="grid w-full grid-cols-2">
          <TabsTrigger value="activities">Activities</TabsTrigger>
          <TabsTrigger value="learners" as-child>
            <RouterLink
              :to="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }"
            >
              Learners
            </RouterLink>
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </template>
    <template #right>
      <Button variant="default" size="xs" as-child>
        <RouterLink :to="{ name: 'Modern Cohorts - Assign Activity', params: { cohortId } }">
          <PlusIcon class="mr-2 size-4" />
          Assign Activity
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <MultiTable :data="assignments" :columns="columns" :groups="groups" :loading="isLoading" />
</template>
