<script setup lang="ts">
import { useNotificationStore } from '@/stores/notifications'
import { onBeforeMount, ref } from 'vue'
import ProgressBar from '@/components/utils/ProgressBar.vue'
import AssignmentMandatoryText from '@/components/AssignmentMandatoryText.vue'
import { useAssignmentStore } from '@/stores/assignment'
import Api from '@/open-api'
import { NotificationStatus } from '@/types/notification'
import { TaskTypeApiEnum, type MyTask } from '@/open-api/generated'
import type {
  MyConversationTaskMeta,
  MyFeedbackTaskMeta,
  MyPreLearningTaskMeta,
  MyReflectionTaskMeta
} from '@/types/api'
import dayjs from 'dayjs'
import CustomButton from '@/components/utils/CustomButton.vue'

import {
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  XCircleIcon
} from '@heroicons/vue/24/outline'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import HeaderList from '@/components/my-assignments/HeaderList.vue'
import { RichTextReader } from '@/components/rich-text'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'MyAssignments Details',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const router = useRouter()
const route = useRoute('MyAssignments Details')
const assignmentStore = useAssignmentStore()
const authStore = useAuthStore()

enum TaskStatus {
  NotStarted = 'NotStarted',
  Passed = 'Passed',
  Failed = 'Failed',
  NoStatus = 'NoStatus'
}

// ==================================================
// Assignment details
// ==================================================

const assignmentTaskLoading = ref(false)

onBeforeMount(() => {
  assignmentTaskLoading.value = true

  Api.MyAssignments.getMyAssignmentEndpoint(route.params.assignmentId as string)
    .then(async (res) => {
      assignmentStore.setCurrentAssignment(res.assignment)
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      assignmentTaskLoading.value = false
    })
})

const formatDate = (val: string) => {
  return dayjs(val).format('D MMM YYYY, hh:mma')
}

// ==================================================
// Header details
// ==================================================

const headerFields = ref([
  {
    title: 'DUE DATE',
    mobileVisible: true,
    value: formatDate(assignmentStore.currentAssignment?.due_date || '')
  },
  {
    title: 'COHORT',
    value: assignmentStore.currentAssignment?.cohort_name || '',
    mobileVisible: false
  }
])

// ==================================================
// Task details
// ==================================================

const getTaskStatus = (task: MyTask) => {
  if (task.task_type === TaskTypeApiEnum.CONVERSATION_TASK) {
    if ((task.meta as MyConversationTaskMeta).ConversationTask.number_of_attempts <= 0) {
      return TaskStatus.NotStarted
    } else if ((task.meta as MyConversationTaskMeta).ConversationTask.satisfactory) {
      return TaskStatus.Passed
    } else {
      return TaskStatus.Failed
    }
  } else if (task.task_type === TaskTypeApiEnum.FEEDBACK_TASK) {
    if ((task.meta as MyFeedbackTaskMeta).FeedbackTask.number_of_attempts <= 0) {
      return TaskStatus.NotStarted
    } else {
      return TaskStatus.Passed
    }
  } else if (task.task_type === TaskTypeApiEnum.PRE_LEARNING_TASK) {
    if ((task.meta as MyPreLearningTaskMeta).PreLearningTask.completed) {
      return TaskStatus.Passed
    } else {
      return TaskStatus.NotStarted
    }
  } else if (task.task_type === TaskTypeApiEnum.REFLECTION_TASK) {
    if ((task.meta as MyReflectionTaskMeta).ReflectionTask.number_of_attempts <= 0) {
      return TaskStatus.NotStarted
    } else {
      return TaskStatus.Passed
    }
  } else {
    return TaskStatus.NoStatus
  }
}

const viewTask = (task: MyTask) => {
  router.push({
    name: 'MyAssignments Task',
    params: { taskId: task.task_id, assignmentId: route.params.assignmentId }
  })
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col gap-5">
      <div
        class="flex cursor-pointer items-center gap-2 text-sc-grey-700"
        @click="
          authStore.flagModernEnabled
            ? router.push({ name: 'Modern - My Activities' })
            : router.push({ name: 'MyAssignments' })
        "
      >
        <ChevronLeftIcon class="h-4 w-4" />
        <h4 class="font-normal text-sc-grey-700">Back to My Assignments</h4>
      </div>

      <div class="flex flex-col">
        <small class="text-sc-grey-700">Assignment</small>
        <h2 class="text-xl">{{ assignmentStore.currentAssignment?.name }}</h2>
      </div>

      <HeaderList :fields="headerFields" />
    </div>
    <div
      v-if="assignmentTaskLoading || assignmentStore.currentAssignment?.mandatory_task_total_count"
      class="flex flex-col gap-1.5 pt-5"
    >
      <AssignmentMandatoryText
        :class="['text-sm', { 'skeleton-loading': assignmentTaskLoading }]"
        :mandatory-total="assignmentStore.currentAssignment!.mandatory_task_total_count"
        :mandatory-completed="assignmentStore.currentAssignment!.mandatory_task_completed_count"
      />
      <ProgressBar
        :class="['w-full', { 'skeleton-loading': assignmentTaskLoading }]"
        :step="assignmentStore.currentAssignment?.mandatory_task_completed_count || 0"
        :steps="assignmentStore.currentAssignment?.mandatory_task_total_count || 0"
      />
    </div>

    <RichTextReader :content="assignmentStore?.currentAssignment?.description || ''" />

    <h3 class="flex-none text-lg">
      Tasks
      <span v-if="!assignmentTaskLoading" class="text-sc-grey-600">{{
        `(${assignmentStore?.currentAssignment?.tasks?.length || 0})`
      }}</span>
    </h3>

    <AppLoadingSpinner v-if="assignmentTaskLoading" class="pt-20" loading />

    <div
      v-else-if="!assignmentStore?.currentAssignment?.tasks.length"
      class="flex items-center justify-center text-sc-grey-600"
    >
      You have no tasks to display
    </div>

    <div v-else class="flex flex-col">
      <div
        v-for="(task, taskIndex) in assignmentStore?.currentAssignment?.tasks"
        :key="`task_${taskIndex}`"
        :class="[
          `group mt-2 flex cursor-pointer justify-between gap-3 rounded-xl border border-sc-grey-300 p-3 hover:bg-sc-grey-50`
        ]"
        @click="viewTask(task)"
      >
        <div class="flex flex-1 items-center justify-between gap-5 overflow-hidden">
          <div class="flex items-center gap-5 overflow-hidden">
            <h3 class="text-lg font-normal text-sc-grey-600 group-hover:text-sc-grey-700">
              {{ `${taskIndex + 1 < 10 ? 0 : ''}${taskIndex + 1}` }}
            </h3>

            <div class="flex flex-col truncate">
              <div class="truncate">
                {{ task.name }}
              </div>
              <div :class="['text-sc-grey-600']">
                {{ task.mandatory ? 'Mandatory' : 'Optional' }}
              </div>
            </div>
          </div>

          <CustomButton
            v-if="getTaskStatus(task) === TaskStatus.NotStarted"
            button-size="md"
            class="task-status"
          >
            View Task
          </CustomButton>
          <CustomButton
            v-else-if="getTaskStatus(task) === TaskStatus.Passed"
            class="task-status"
            :custom-style="`border bg-green-100 border-green-500 text-sc-grey-700`"
            :start-icon="CheckCircleIcon"
            :icon-size="`h-5 w-5 text-green-500`"
            button-size="md"
          >
            Passed
          </CustomButton>
          <CustomButton
            v-else-if="
              task.task_type === TaskTypeApiEnum.CONVERSATION_TASK &&
              (task.meta as MyConversationTaskMeta).ConversationTask.number_of_attempts ===
                (task.meta as MyConversationTaskMeta).ConversationTask.max_attempts
            "
            class="task-status"
            :custom-style="`border bg-red-100 border-red-500 text-sc-grey-700`"
            :start-icon="XCircleIcon"
            button-size="md"
            :icon-size="`h-5 w-5 text-red-500`"
          >
            Didn’t Pass
          </CustomButton>
          <CustomButton
            v-else-if="
              task.task_type === TaskTypeApiEnum.CONVERSATION_TASK &&
              (task.meta as MyConversationTaskMeta).ConversationTask.number_of_attempts !==
                (task.meta as MyConversationTaskMeta).ConversationTask.max_attempts
            "
            :start-icon="ArrowPathIcon"
            class="task-status"
            button-size="md"
          >
            Retry Task
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.task-status {
  /* width 141px is the width of the larges button */
  @apply w-[141px];
}
</style>
