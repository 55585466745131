import _definePage_default_0 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/[...path].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/index.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/access.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/assign.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/preview.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/new.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/authorize.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/browser-not-supported.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/index.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId]/details.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId]/insights.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId]/rubric.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId].vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task-attempt/[conversationId]/rubric.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task-attempt/[conversationId]/transcript.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task-attempt/[conversationId].vue?definePage&vue&lang.tsx'
import _definePage_default_20 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignments.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/details.vue?definePage&vue&lang.tsx'
import _definePage_default_22 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/learners.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId].vue?definePage&vue&lang.tsx'
import _definePage_default_24 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId]/character.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId]/rubric.vue?definePage&vue&lang.tsx'
import _definePage_default_26 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId]/transcript.vue?definePage&vue&lang.tsx'
import _definePage_default_27 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId].vue?definePage&vue&lang.tsx'
import _definePage_default_28 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/index.vue?definePage&vue&lang.tsx'
import _definePage_default_29 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_30 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/[userId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_31 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/[userId]/[taskAttemptId].vue?definePage&vue&lang.tsx'
import _definePage_default_32 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/preview.vue?definePage&vue&lang.tsx'
import _definePage_default_33 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/activities.vue?definePage&vue&lang.tsx'
import _definePage_default_34 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/activities.add.vue?definePage&vue&lang.tsx'
import _definePage_default_35 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/details.vue?definePage&vue&lang.tsx'
import _definePage_default_36 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/learners.vue?definePage&vue&lang.tsx'
import _definePage_default_37 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/learners.add.vue?definePage&vue&lang.tsx'
import _definePage_default_38 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/administer.vue?definePage&vue&lang.tsx'
import _definePage_default_39 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/new.vue?definePage&vue&lang.tsx'
import _definePage_default_40 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/index.vue?definePage&vue&lang.tsx'
import _definePage_default_41 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/access-controls.vue?definePage&vue&lang.tsx'
import _definePage_default_42 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/actions.vue?definePage&vue&lang.tsx'
import _definePage_default_43 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/config.vue?definePage&vue&lang.tsx'
import _definePage_default_44 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/investigations.vue?definePage&vue&lang.tsx'
import _definePage_default_45 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/prompts.vue?definePage&vue&lang.tsx'
import _definePage_default_46 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId].vue?definePage&vue&lang.tsx'
import _definePage_default_47 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/index.vue?definePage&vue&lang.tsx'
import _definePage_default_48 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/[rubricId]/access-controls.vue?definePage&vue&lang.tsx'
import _definePage_default_49 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/[rubricId]/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_50 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/[rubricId].vue?definePage&vue&lang.tsx'
import _definePage_default_51 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/index.vue?definePage&vue&lang.tsx'
import _definePage_default_52 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/[sectionTemplateId]/access-controls.vue?definePage&vue&lang.tsx'
import _definePage_default_53 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/[sectionTemplateId]/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_54 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/[sectionTemplateId].vue?definePage&vue&lang.tsx'
import _definePage_default_55 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/index.vue?definePage&vue&lang.tsx'
import _definePage_default_56 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/[conversationId]/rubric.vue?definePage&vue&lang.tsx'
import _definePage_default_57 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/[conversationId]/transcript/[[taskAttemptId]].vue?definePage&vue&lang.tsx'
import _definePage_default_58 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/[conversationId].vue?definePage&vue&lang.tsx'
import _definePage_default_59 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/login/[[organizationId]].vue?definePage&vue&lang.tsx'
import _definePage_default_60 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/billing.vue?definePage&vue&lang.tsx'
import _definePage_default_61 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/feature-flags.vue?definePage&vue&lang.tsx'
import _definePage_default_62 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/organization.vue?definePage&vue&lang.tsx'
import _definePage_default_63 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/users.vue?definePage&vue&lang.tsx'
import _definePage_default_64 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management.vue?definePage&vue&lang.tsx'
import _definePage_default_65 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/my-activities/index.vue?definePage&vue&lang.tsx'
import _definePage_default_66 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/my-activities/[activityId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_67 from '/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/my-activities/[activityId]/[taskId]/[taskAttemptId].vue?definePage&vue&lang.tsx'
import _definePage_default_68 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/index.vue?definePage&vue&lang.tsx'
import _definePage_default_69 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/details.vue?definePage&vue&lang.tsx'
import _definePage_default_70 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/[taskId]/index.vue?definePage&vue&lang.tsx'
import _definePage_default_71 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/[taskId]/history.vue?definePage&vue&lang.tsx'
import _definePage_default_72 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/prelearning/[taskId].vue?definePage&vue&lang.tsx'
import _definePage_default_73 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/reflection/[taskId].vue?definePage&vue&lang.tsx'
import _definePage_default_74 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId]/character.vue?definePage&vue&lang.tsx'
import _definePage_default_75 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId]/rubric.vue?definePage&vue&lang.tsx'
import _definePage_default_76 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId]/transcript.vue?definePage&vue&lang.tsx'
import _definePage_default_77 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId].vue?definePage&vue&lang.tsx'
import _definePage_default_78 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/index.vue?definePage&vue&lang.tsx'
import _definePage_default_79 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/[conversationId]/rubric.vue?definePage&vue&lang.tsx'
import _definePage_default_80 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/[conversationId]/transcript/[[taskAttemptId]].vue?definePage&vue&lang.tsx'
import _definePage_default_81 from '/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/[conversationId].vue?definePage&vue&lang.tsx'
import _definePage_default_82 from '/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_83 from '/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/[sceneId].vue?definePage&vue&lang.tsx'
import _definePage_default_84 from '/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/web-mode/index.vue?definePage&vue&lang.tsx'
import _definePage_default_85 from '/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/web-mode/[sceneId].vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: '404',
    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/activities',
    /* internal name: '/activities' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Modern Activities - All Activities List',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
      {
        path: ':activityId',
        /* internal name: '/activities/[activityId]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Modern Activities - Edit Activity',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/index.vue'),
            /* no children */
          },
  _definePage_default_4
  ),
  _mergeRouteRecord(
          {
            path: 'access',
            name: 'Modern Activities - Activity Access List',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/access.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
  _mergeRouteRecord(
          {
            path: 'assign',
            name: 'Modern Activities - Assign to Cohort',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/assign.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
  _mergeRouteRecord(
          {
            path: 'preview',
            name: 'Modern Activities - Preview Activity',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/[activityId]/preview.vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'new',
        name: 'Modern Activities - New Activity',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/activities/new.vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/authorize',
    name: 'Authorize from Auth0',
    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/authorize.vue'),
    /* no children */
  },
  _definePage_default_9
  ),
  _mergeRouteRecord(
  {
    path: '/browser-not-supported',
    name: 'Unsupported Browser',
    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/browser-not-supported.vue'),
    /* no children */
  },
  _definePage_default_10
  ),
  {
    path: '/cohort',
    /* internal name: '/cohort' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Cohorts',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/index.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: ':cohortId',
        name: 'Cohort',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId].vue'),
        children: [
          {
            path: 'assignment',
            /* internal name: '/cohort/[cohortId]/assignment' */
            /* no component */
            children: [
              {
                path: ':assignmentId',
                /* internal name: '/cohort/[cohortId]/assignment/[assignmentId]' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'Cohort Assignment Details',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/index.vue'),
                    /* no children */
                  },
  _definePage_default_12
  ),
                  {
                    path: 'task',
                    /* internal name: '/cohort/[cohortId]/assignment/[assignmentId]/task' */
                    /* no component */
                    children: [
  _mergeRouteRecord(
                      {
                        path: ':taskId',
                        name: 'Cohort Assignment Task',
                        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId].vue'),
                        children: [
  _mergeRouteRecord(
                          {
                            path: 'details',
                            name: 'Cohort Assignment Task Details',
                            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId]/details.vue'),
                            /* no children */
                          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
                          {
                            path: 'insights',
                            name: 'Cohort Assignment Task Insights',
                            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId]/insights.vue'),
                            /* no children */
                          },
  _definePage_default_14
  ),
  _mergeRouteRecord(
                          {
                            path: 'rubric',
                            name: 'Cohort Assignment Task Rubric',
                            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task/[taskId]/rubric.vue'),
                            /* no children */
                          },
  _definePage_default_15
  )
                        ],
                      },
  _definePage_default_16
  )
                    ],
                  },
                  {
                    path: 'task-attempt',
                    /* internal name: '/cohort/[cohortId]/assignment/[assignmentId]/task-attempt' */
                    /* no component */
                    children: [
  _mergeRouteRecord(
                      {
                        path: ':conversationId',
                        name: 'Cohort Assignment Task Attempt Wrapper',
                        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task-attempt/[conversationId].vue'),
                        children: [
  _mergeRouteRecord(
                          {
                            path: 'rubric',
                            name: 'Cohort Assignment Task Attempt Rubric',
                            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task-attempt/[conversationId]/rubric.vue'),
                            /* no children */
                          },
  _definePage_default_17
  ),
  _mergeRouteRecord(
                          {
                            path: 'transcript',
                            name: 'Cohort Assignment Task Attempt Transcript',
                            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignment/[assignmentId]/task-attempt/[conversationId]/transcript.vue'),
                            /* no children */
                          },
  _definePage_default_18
  )
                        ],
                      },
  _definePage_default_19
  )
                    ],
                  }
                ],
              }
            ],
          },
  _mergeRouteRecord(
          {
            path: 'assignments',
            name: 'Cohort Assignments',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/assignments.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'details',
            name: 'Cohort Details',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/details.vue'),
            /* no children */
          },
  _definePage_default_21
  ),
  _mergeRouteRecord(
          {
            path: 'learners',
            name: 'Cohort Learners',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/[cohortId]/learners.vue'),
            /* no children */
          },
  _definePage_default_22
  )
        ],
      },
  _definePage_default_23
  ),
      {
        path: 'preview-task',
        /* internal name: '/cohort/preview-task' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':conversationId',
            name: 'Cohort Wrapper',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'character',
                name: 'Cohort Conversation',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId]/character.vue'),
                /* no children */
              },
  _definePage_default_24
  ),
  _mergeRouteRecord(
              {
                path: 'rubric',
                name: 'Cohort Conversation Rubric',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId]/rubric.vue'),
                /* no children */
              },
  _definePage_default_25
  ),
  _mergeRouteRecord(
              {
                path: 'transcript',
                name: 'Cohort Conversation Transcript',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/cohort/preview-task/[conversationId]/transcript.vue'),
                /* no children */
              },
  _definePage_default_26
  )
            ],
          },
  _definePage_default_27
  )
        ],
      }
    ],
  },
  {
    path: '/cohorts',
    /* internal name: '/cohorts' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Modern Cohorts - My Cohorts List',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/index.vue'),
        /* no children */
      },
  _definePage_default_28
  ),
      {
        path: ':cohortId',
        /* internal name: '/cohorts/[cohortId]' */
        /* no component */
        children: [
          {
            path: ':assignmentInstanceId',
            /* internal name: '/cohorts/[cohortId]/[assignmentInstanceId]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'Modern Cohorts - Activity Instance',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/index.vue'),
                /* no children */
              },
  _definePage_default_29
  ),
              {
                path: ':userId',
                /* internal name: '/cohorts/[cohortId]/[assignmentInstanceId]/[userId]' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'Modern Cohorts - Individual Student Attempts',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/[userId]/index.vue'),
                    /* no children */
                  },
  _definePage_default_30
  ),
  _mergeRouteRecord(
                  {
                    path: ':taskAttemptId',
                    name: 'Modern Educator - Review Task Attempt',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/[userId]/[taskAttemptId].vue'),
                    /* no children */
                  },
  _definePage_default_31
  )
                ],
              },
  _mergeRouteRecord(
              {
                path: 'preview',
                name: 'Modern Cohorts - Preview Activity Instance',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/[assignmentInstanceId]/preview.vue'),
                /* no children */
              },
  _definePage_default_32
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'activities',
            name: 'Modern Cohorts - Cohort Activities List',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/activities.vue'),
            /* no children */
          },
  _definePage_default_33
  ),
  _mergeRouteRecord(
          {
            path: 'activities/add',
            name: 'Modern Cohorts - Assign Activity',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/activities.add.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
  _mergeRouteRecord(
          {
            path: 'details',
            name: 'Modern Cohorts - Cohort Details',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/details.vue'),
            /* no children */
          },
  _definePage_default_35
  ),
  _mergeRouteRecord(
          {
            path: 'learners',
            name: 'Modern Cohorts - Cohort Learners List',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/learners.vue'),
            /* no children */
          },
  _definePage_default_36
  ),
  _mergeRouteRecord(
          {
            path: 'learners/add',
            name: 'Modern Cohorts - Add Learners to Cohort',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/[cohortId]/learners.add.vue'),
            /* no children */
          },
  _definePage_default_37
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'administer',
        name: 'Modern Cohorts - Cohort Administration',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/administer.vue'),
        /* no children */
      },
  _definePage_default_38
  ),
  _mergeRouteRecord(
      {
        path: 'new',
        name: 'Modern Cohorts - New Cohort',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/cohorts/new.vue'),
        /* no children */
      },
  _definePage_default_39
  )
    ],
  },
  {
    path: '/content',
    /* internal name: '/content' */
    /* no component */
    children: [
      {
        path: 'characters',
        /* internal name: '/content/characters' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Characters',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/index.vue'),
            /* no children */
          },
  _definePage_default_40
  ),
  _mergeRouteRecord(
          {
            path: ':characterId',
            name: 'Character',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'access-controls',
                name: 'Character Access Controls',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/access-controls.vue'),
                /* no children */
              },
  _definePage_default_41
  ),
  _mergeRouteRecord(
              {
                path: 'actions',
                name: 'Character Actions',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/actions.vue'),
                /* no children */
              },
  _definePage_default_42
  ),
  _mergeRouteRecord(
              {
                path: 'config',
                name: 'Character Config',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/config.vue'),
                /* no children */
              },
  _definePage_default_43
  ),
  _mergeRouteRecord(
              {
                path: 'investigations',
                name: 'Character Investigations',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/investigations.vue'),
                /* no children */
              },
  _definePage_default_44
  ),
  _mergeRouteRecord(
              {
                path: 'prompts',
                name: 'Character Prompts',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/characters/[characterId]/prompts.vue'),
                /* no children */
              },
  _definePage_default_45
  )
            ],
          },
  _definePage_default_46
  )
        ],
      },
      {
        path: 'rubrics',
        /* internal name: '/content/rubrics' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Rubrics',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/index.vue'),
            /* no children */
          },
  _definePage_default_47
  ),
  _mergeRouteRecord(
          {
            path: ':rubricId',
            name: 'Rubric',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/[rubricId].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'access-controls',
                name: 'Rubric Access Controls',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/[rubricId]/access-controls.vue'),
                /* no children */
              },
  _definePage_default_48
  ),
  _mergeRouteRecord(
              {
                path: 'edit',
                name: 'Rubric View',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/rubrics/[rubricId]/edit.vue'),
                /* no children */
              },
  _definePage_default_49
  )
            ],
          },
  _definePage_default_50
  )
        ],
      },
      {
        path: 'sections',
        /* internal name: '/content/sections' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Sections',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/index.vue'),
            /* no children */
          },
  _definePage_default_51
  ),
  _mergeRouteRecord(
          {
            path: ':sectionTemplateId',
            name: 'Section',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/[sectionTemplateId].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'access-controls',
                name: 'Section Access Controls',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/[sectionTemplateId]/access-controls.vue'),
                /* no children */
              },
  _definePage_default_52
  ),
  _mergeRouteRecord(
              {
                path: 'edit',
                name: 'Section View',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/content/sections/[sectionTemplateId]/edit.vue'),
                /* no children */
              },
  _definePage_default_53
  )
            ],
          },
  _definePage_default_54
  )
        ],
      }
    ],
  },
  {
    path: '/history',
    /* internal name: '/history' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'History',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/index.vue'),
        /* no children */
      },
  _definePage_default_55
  ),
  _mergeRouteRecord(
      {
        path: ':conversationId',
        name: 'History Wrapper',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/[conversationId].vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'rubric',
            name: 'History Rubric',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/[conversationId]/rubric.vue'),
            /* no children */
          },
  _definePage_default_56
  ),
          {
            path: 'transcript',
            /* internal name: '/history/[conversationId]/transcript' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':taskAttemptId?',
                name: 'History Transcript',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/history/[conversationId]/transcript/[[taskAttemptId]].vue'),
                /* no children */
              },
  _definePage_default_57
  )
            ],
          }
        ],
      },
  _definePage_default_58
  )
    ],
  },
  {
    path: '/login',
    /* internal name: '/login' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':organizationId?',
        name: 'Login',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/login/[[organizationId]].vue'),
        /* no children */
      },
  _definePage_default_59
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/management',
    name: 'Management',
    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'billing',
        name: 'Management Billing',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/billing.vue'),
        /* no children */
      },
  _definePage_default_60
  ),
  _mergeRouteRecord(
      {
        path: 'feature-flags',
        name: 'Management Feature Flags',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/feature-flags.vue'),
        /* no children */
      },
  _definePage_default_61
  ),
  _mergeRouteRecord(
      {
        path: 'organization',
        name: 'Management Organization',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/organization.vue'),
        /* no children */
      },
  _definePage_default_62
  ),
  _mergeRouteRecord(
      {
        path: 'users',
        name: 'Management Users',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/management/users.vue'),
        /* no children */
      },
  _definePage_default_63
  )
    ],
  },
  _definePage_default_64
  ),
  {
    path: '/my-activities',
    /* internal name: '/my-activities' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Modern - My Activities',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/my-activities/index.vue'),
        /* no children */
      },
  _definePage_default_65
  ),
      {
        path: ':activityId',
        /* internal name: '/my-activities/[activityId]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Modern - Learner Activity View',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/my-activities/[activityId]/index.vue'),
            /* no children */
          },
  _definePage_default_66
  ),
          {
            path: ':taskId',
            /* internal name: '/my-activities/[activityId]/[taskId]' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':taskAttemptId',
                name: 'Modern - Attempt Task or Review Task Attempt',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/modern-pages/my-activities/[activityId]/[taskId]/[taskAttemptId].vue'),
                /* no children */
              },
  _definePage_default_67
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/my-assignments',
    /* internal name: '/my-assignments' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'MyAssignments',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/index.vue'),
        /* no children */
      },
  _definePage_default_68
  ),
      {
        path: ':assignmentId',
        /* internal name: '/my-assignments/[assignmentId]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'details',
            name: 'MyAssignments Details',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/details.vue'),
            /* no children */
          },
  _definePage_default_69
  ),
          {
            path: 'task',
            /* internal name: '/my-assignments/[assignmentId]/task' */
            /* no component */
            children: [
              {
                path: ':taskId',
                /* internal name: '/my-assignments/[assignmentId]/task/[taskId]' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'MyAssignments Task',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/[taskId]/index.vue'),
                    /* no children */
                  },
  _definePage_default_70
  ),
  _mergeRouteRecord(
                  {
                    path: 'history',
                    name: 'MyAssignments Task History',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/[taskId]/history.vue'),
                    /* no children */
                  },
  _definePage_default_71
  )
                ],
              },
              {
                path: 'prelearning',
                /* internal name: '/my-assignments/[assignmentId]/task/prelearning' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: ':taskId',
                    name: 'MyAssignments Prelearning Task',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/prelearning/[taskId].vue'),
                    /* no children */
                  },
  _definePage_default_72
  )
                ],
              },
              {
                path: 'reflection',
                /* internal name: '/my-assignments/[assignmentId]/task/reflection' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: ':taskId',
                    name: 'MyAssignments Reflection Task',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/[assignmentId]/task/reflection/[taskId].vue'),
                    /* no children */
                  },
  _definePage_default_73
  )
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'task-attempt',
        /* internal name: '/my-assignments/task-attempt' */
        /* no component */
        children: [
          {
            path: ':taskAttemptId',
            /* internal name: '/my-assignments/task-attempt/[taskAttemptId]' */
            /* no component */
            children: [
              {
                path: 'conversation',
                /* internal name: '/my-assignments/task-attempt/[taskAttemptId]/conversation' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: ':conversationId',
                    name: 'MyAssignments Wrapper',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId].vue'),
                    children: [
  _mergeRouteRecord(
                      {
                        path: 'character',
                        name: 'MyAssignments Conversation',
                        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId]/character.vue'),
                        /* no children */
                      },
  _definePage_default_74
  ),
  _mergeRouteRecord(
                      {
                        path: 'rubric',
                        name: 'MyAssignments Conversation Rubric',
                        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId]/rubric.vue'),
                        /* no children */
                      },
  _definePage_default_75
  ),
  _mergeRouteRecord(
                      {
                        path: 'transcript',
                        name: 'MyAssignments Conversation Transcript',
                        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/my-assignments/task-attempt/[taskAttemptId]/conversation/[conversationId]/transcript.vue'),
                        /* no children */
                      },
  _definePage_default_76
  )
                    ],
                  },
  _definePage_default_77
  )
                ],
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/organization',
    /* internal name: '/organization' */
    /* no component */
    children: [
      {
        path: 'history',
        /* internal name: '/organization/history' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Organization History',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/index.vue'),
            /* no children */
          },
  _definePage_default_78
  ),
  _mergeRouteRecord(
          {
            path: ':conversationId',
            name: 'Organization History Wrapper',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/[conversationId].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'rubric',
                name: 'Organization History Rubric',
                component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/[conversationId]/rubric.vue'),
                /* no children */
              },
  _definePage_default_79
  ),
              {
                path: 'transcript',
                /* internal name: '/organization/history/[conversationId]/transcript' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: ':taskAttemptId?',
                    name: 'Organization History Transcript',
                    component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/pages/organization/history/[conversationId]/transcript/[[taskAttemptId]].vue'),
                    /* no children */
                  },
  _definePage_default_80
  )
                ],
              }
            ],
          },
  _definePage_default_81
  )
        ],
      }
    ],
  },
  {
    path: '/vr',
    /* internal name: '/vr' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'VR',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/index.vue'),
        /* no children */
      },
  _definePage_default_82
  ),
  _mergeRouteRecord(
      {
        path: ':sceneId',
        name: 'VR Scene',
        component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/[sceneId].vue'),
        /* no children */
      },
  _definePage_default_83
  ),
      {
        path: 'web-mode',
        /* internal name: '/vr/web-mode' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'Web-mode VR',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/web-mode/index.vue'),
            /* no children */
          },
  _definePage_default_84
  ),
  _mergeRouteRecord(
          {
            path: ':sceneId',
            name: 'Web-mode VR Scene',
            component: () => import('/home/runner/work/monorepo/monorepo/ui/ada/src/vr/pages/web-mode/[sceneId].vue'),
            /* no children */
          },
  _definePage_default_85
  )
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

