<script setup lang="ts">
import { watch, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetAssignment from '@/composables/api/queries/useGetAssignment'
import useTasks from '@/composables/useTasks'
import useUpdateAssignmentV2 from '@/composables/api/mutations/useUpdateAssignmentV2'
import { schema, type Schema } from '@/composables/api/mutations/schema/useUpdateAssignmentV2'
import useUpdateAssignmentTasks, {
  mapTaskToForm
} from '@/composables/api/mutations/useUpdateAssignmentTasks'
import useComposeMutations from '@/composables/api/useComposeMutations'
import { CommandState } from '@/composables/api/mutations/types'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import {
  LABEL_ACTIVITY_NAME,
  LABEL_ACTIVITY_DESCRIPTION,
  LABEL_ACTIVITY_INSTRUCTIONS,
  DESCRIPTION_ACTIVITY_NAME,
  DESCRIPTION_ACTIVITY_DESCRIPTION,
  DESCRIPTION_ACTIVITY_INSTRUCTIONS
} from '@/constants/forms'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { NewTask, AnyTaskForm } from '@/components/modern/task-forms'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import { Button } from '@/components/modern/ui/button'
import { EyeOpenIcon, PlusCircledIcon, CheckboxIcon, PlayIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Activities - Edit Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Edit Activity')

const authStore = useAuthStore()

const route = useRoute('Modern Activities - Edit Activity')
const assignmentId = computed<string>(() => route.params.activityId)

const notificationStore = useNotificationStore()

const { assignment, refetch } = useGetAssignment({ assignmentId, notificationStore })
const { tasks, addTask, ...handlers } = useTasks(assignment)

const { track, state, reset } = useComposeMutations()
const updateAssignment = track(useUpdateAssignmentV2({ assignmentId, notificationStore }))
const updateTasks = track(useUpdateAssignmentTasks({ assignmentId, notificationStore }))

const form = useForm({ validationSchema: toTypedSchema(schema) })

const hasTasks = computed<boolean>(() => tasks.value.length > 0)
const taskErrors = ref<string[][]>([])
const hasTaskErrors = computed<boolean>(() => taskErrors.value.flat().length > 0)

const submit = form.handleSubmit(async (values: Schema) => {
  if (hasTaskErrors.value) {
    notificationStore.addDANGER(taskErrors.value.flat()[0])
    return
  }
  await Promise.all([
    updateAssignment(values),
    updateTasks({ tasks: tasks.value.map(mapTaskToForm) })
  ])
  if (state.value === CommandState.SUCCESS) {
    refetch()
  }
  reset()
})

const fieldConfig: Config<Schema> = {
  name: {
    label: LABEL_ACTIVITY_NAME,
    description: DESCRIPTION_ACTIVITY_NAME
  },
  description: {
    label: LABEL_ACTIVITY_DESCRIPTION,
    component: 'textarea',
    description: DESCRIPTION_ACTIVITY_DESCRIPTION
  },
  instructions: {
    label: LABEL_ACTIVITY_INSTRUCTIONS,
    hideRequired: true,
    component: 'richtext',
    description: DESCRIPTION_ACTIVITY_INSTRUCTIONS
  }
}

// Fill in the form with the server values from GetAssignment
watch(assignment, () => {
  form.resetForm({
    values: {
      name: assignment.value?.name,
      description: assignment.value?.description,
      instructions: assignment.value?.instructions
    }
  })
})
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Modern Activities - All Activities List' }" name="all activities" />
    </template>
    <template #right>
      <Button v-if="authStore.isAtLeastInstitutionAdminUser" variant="outline" size="xs" as-child>
        <RouterLink
          :to="{
            name: 'Modern Activities - Activity Access List',
            params: { activityId: assignmentId }
          }"
        >
          <EyeOpenIcon class="mr-2 size-4" />
          Access Control
        </RouterLink>
      </Button>
      <Button variant="outline" size="xs" as-child>
        <RouterLink
          :to="{
            name: 'Modern Activities - Preview Activity',
            params: { activityId: assignmentId }
          }"
        >
          <PlayIcon class="mr-2 size-4" />
          Preview Activity
        </RouterLink>
      </Button>
      <Button variant="default" size="xs" as-child>
        <RouterLink
          :to="{
            name: 'Modern Activities - Assign to Cohort',
            params: { activityId: assignmentId }
          }"
        >
          <PlusCircledIcon class="mr-2 size-4" />
          Assign to Cohort
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <div class="flex w-full flex-row justify-center px-4 lg:px-6">
    <div class="w-full max-w-4xl space-y-6">
      <AutoForm :schema="schema" :form="form" :field-config="fieldConfig">
        <Button variant="default" size="xs" @click="submit">
          <CheckboxIcon class="mr-2 size-4" />
          <span>Save Changes</span>
        </Button>
      </AutoForm>
      <div class="w-full flex-col space-y-6">
        <template v-if="hasTasks">
          <AnyTaskForm
            v-for="(task, index) in tasks"
            :key="task.assignmentTaskId"
            :task="task"
            :index="index"
            :is-last="index === tasks.length - 1"
            v-on="handlers"
            @task-errors="(index, errors) => (taskErrors[index] = errors)"
          />
          <div class="ml-4 space-y-6 pl-9 lg:ml-6">
            <Button variant="default" size="xs" @click="submit">
              <CheckboxIcon class="mr-2 size-4" />
              <span>Save Changes</span>
            </Button>
            <NewTask @add-task="addTask" />
          </div>
        </template>
        <template v-else>
          <p class="w-full text-center text-muted-foreground">
            No tasks have been added yet. Add a task from the options below.
          </p>
          <NewTask @add-task="addTask" />
        </template>
      </div>
    </div>
  </div>
</template>
