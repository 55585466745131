<script setup lang="ts">
import { computed, type Ref } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import type {
  GetAssignmentInstanceStudentInsightsOutput,
  CohortAssignmentInsight
} from '@/open-api/generated'
import { mockInstanceFromInsights } from '@/utils/preview-mock-instance'
import useCombineQueries from '@/composables/api/useCombineQueries'
import useGetCohortV2 from '@/composables/api/queries/useGetCohortV2'
import useGetCohortAssignmentInsights from '@/composables/api/queries/useGetCohortAssignmentInsights'
import useGetAssignmentInstanceStudentInsight from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import useGetAssignmentInstanceStudentInsights from '@/composables/api/queries/useGetAssignmentInstanceStudentInsights'
import usePreviewActivity from '@/composables/usePreviewActivity'
import useTaskAttemptState from '@/composables/useTaskAttemptState'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import useFormattedFallbackDate from '@/composables/useFormattedFallbackDate'
import {
  ActivityView,
  TaskView,
  PreviewAttemptsList
} from '@/components/modern/activities-tasks-view'
import { ReviewTask, PerformTask } from '@/components/modern/task-attempts'
import { TopLine, BackButton, ForwardButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { PlayIcon } from '@radix-icons/vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import PreviewTasksList from '@/components/modern/activities-tasks-view/PreviewTasksList.vue'
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/vue/24/outline'

definePage({
  name: 'Modern Cohorts - Preview Activity Instance',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Preview Activity')

const route = useRoute('Modern Cohorts - Preview Activity Instance')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)

const notificationStore = useNotificationStore()

const findUserId = (
  insights: Ref<GetAssignmentInstanceStudentInsightsOutput | null>
): string | null =>
  insights.value?.status.flatMap((status) =>
    status.payload.flatMap((payload) => payload.user.id)
  )[0] ?? null

const findCohortInsight = (cohortInsights: Ref<CohortAssignmentInsight[]>) =>
  cohortInsights.value.find((a) => a.assignment_instance_id === assignmentInstanceId.value) ?? null

const { track, isLoading } = useCombineQueries()
const { cohort } = track(useGetCohortV2({ cohortId, notificationStore }))
const { assignments } = track(useGetCohortAssignmentInsights({ cohortId, notificationStore }))
const cohortInsight = computed(() => findCohortInsight(assignments))
const { assignmentInstanceStudentInsights } = track(
  useGetAssignmentInstanceStudentInsights({ assignmentInstanceId, notificationStore })
)
const userId = computed(() => findUserId(assignmentInstanceStudentInsights))
const { tasks } = track(
  useGetAssignmentInstanceStudentInsight({ assignmentInstanceId, userId, notificationStore })
)

const mockAssignmentInstance = computed(() => {
  if (cohort.value && cohortInsight.value && userId.value) {
    return mockInstanceFromInsights(cohort.value, cohortInsight.value, tasks.value)
  } else {
    return null
  }
})

const {
  selectTask,
  deselectTask,

  taskAttemptId,
  viewAttempt,
  stopViewingAttempt,

  task,
  taskAttempt,
  attempts,
  allAttempts,

  startTaskPreview,
  startTaskPreviewDirectly,
  handleCompleted,
  returnToPreviewingActivity,
  dismissAttempt,

  hasIncompleteAttempts,
  inTaskPreview
} = usePreviewActivity({ mockAssignmentInstance, notificationStore })

const { completed, inRubric, showRubric, canShowRubric, hideRubric, canHideRubric } =
  useTaskAttemptState({ task, taskAttempt })

useScrollToTopOnChange(() => task.value?.task_id)

const { dateShort } = useFormattedFallbackDate(
  () => mockAssignmentInstance.value?.due_date,
  'Preview',
  'Preview'
)
</script>

<template>
  <TopLine>
    <template #center>
      <Button variant="destructive" size="xs" class="w-min" as-child>
        <RouterLink
          :to="{
            name: 'Modern Cohorts - Activity Instance',
            params: { cohortId, assignmentInstanceId }
          }"
        >
          <ArrowLeftStartOnRectangleIcon class="mr-2 size-4" />
          <span>Exit Preview</span>
        </RouterLink>
      </Button>
    </template>
    <template #left>
      <BackButton v-if="inTaskPreview && canHideRubric" name="transcript" @click="hideRubric" />
      <BackButton
        v-else-if="inTaskPreview"
        name="previewing activity"
        @click="stopViewingAttempt"
      />
      <BackButton v-else-if="task" name="previewing activity" @click="deselectTask" />
    </template>
    <template v-if="!isLoading" #right>
      <ForwardButton
        v-if="inTaskPreview && canShowRubric"
        text="Continue to feedback"
        @click="showRubric"
      />
      <ForwardButton
        v-else-if="inTaskPreview && completed"
        text="Return to previewing activity"
        @click="returnToPreviewingActivity"
      />
      <template v-else-if="!inTaskPreview && task">
        <Button
          v-if="hasIncompleteAttempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Continue Task</span>
        </Button>
        <Button
          v-else-if="attempts.length && attempts.length < task.max_attempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Retry Task</span>
        </Button>
        <Button
          v-else-if="attempts.length < task.max_attempts"
          variant="default"
          size="xs"
          class="w-min"
          @click="startTaskPreview"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Start Task</span>
        </Button>
      </template>
    </template>
  </TopLine>
  <AppLoadingSpinner v-if="isLoading" loading center-screen />
  <ReviewTask
    v-else-if="inTaskPreview && completed && task && taskAttempt"
    :task-attempt-id="taskAttemptId!"
    :task-payload="task.payload"
    :attempt-payload="taskAttempt.payload"
    :in-rubric="inRubric"
  />
  <PerformTask
    v-else-if="inTaskPreview && task"
    :task-payload="task.payload"
    :attempt-payload="taskAttempt?.payload ?? null"
    :handle-completed="handleCompleted"
  />
  <TaskView
    v-else-if="task && mockAssignmentInstance"
    :task="task"
    :assignment-instance-id="mockAssignmentInstance.assignment_instance_id"
    :formatted-due-date="dateShort"
  >
    <template #attempts>
      <PreviewAttemptsList
        v-if="attempts.length"
        :attempts="attempts"
        :view-attempt="viewAttempt"
        :dismiss-attempt="dismissAttempt"
      />
    </template>
  </TaskView>
  <ActivityView
    v-else-if="mockAssignmentInstance"
    :activity="mockAssignmentInstance"
    :formatted-due-date="dateShort"
  >
    <template #tasks>
      <PreviewTasksList
        :tasks="mockAssignmentInstance.tasks"
        :attempts="allAttempts"
        @select-task="selectTask"
        @start-task="startTaskPreviewDirectly"
      />
    </template>
  </ActivityView>
</template>
