<script setup lang="ts">
import { ref, computed, useTemplateRef } from 'vue'
import type { Table } from '@tanstack/vue-table'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine } from '@/components/modern/page-navigation'
import type { OrganizationAssignment } from '@/open-api/generated'
import useGetOrganizationAssignments from '@/composables/api/queries/useGetOrganizationAssignments'
import useSearchOrganizationAssignments from '@/composables/api/queries/useSearchOrganizationAssignments'
import useDeleteAssignmentV2 from '@/composables/api/mutations/useDeleteAssignmentV2'
import { CommandState } from '@/composables/api/mutations/types'
import {
  DataTable,
  DataTableServerPagination,
  getFilterValue
} from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/organization-activities'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'Modern Activities - All Activities List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('All Activities')

const authStore = useAuthStore()
// Asserting organizationId will always be defined
// Safe to do this because you can't be logged in without an organizationId
const organizationId = computed(() => authStore.organizationId!)

const notificationStore = useNotificationStore()

const { pagination, ...getAssignments } = useGetOrganizationAssignments({
  organizationId,
  notificationStore
})

const table = useTemplateRef<Table<OrganizationAssignment>>('table')
const query = computed(() => getFilterValue(table.value))
const searchAssignments = useSearchOrganizationAssignments({
  organizationId,
  query,
  notificationStore
})

const isQueryActive = computed((): boolean => !!query.value)

const data = computed(() =>
  isQueryActive.value ? searchAssignments.assignments.value : getAssignments.assignments.value
)
const loading = computed(() =>
  isQueryActive.value ? searchAssignments.isLoading.value : getAssignments.isLoading.value
)
const refetch = () => (isQueryActive.value ? searchAssignments.refetch() : getAssignments.refetch())

const assignmentId = ref<string>('')
const { state, execute, reset } = useDeleteAssignmentV2({ assignmentId, notificationStore })
const requestDeleteAssignment = ({ assignment_id }: OrganizationAssignment) => {
  assignmentId.value = assignment_id
}
const confirmDeleteAssignment = async () => {
  await execute()
  if (state.value === CommandState.SUCCESS) {
    refetch()
  }
  reset()
}

const organizationActivities = useColumns({ requestDeleteAssignment, confirmDeleteAssignment })
</script>

<template>
  <TopLine>
    <template #left>
      <span> All Activities </span>
    </template>
    <template #right>
      <Button variant="default" size="xs" as-child>
        <RouterLink :to="{ name: 'Modern Activities - New Activity' }">
          <PlusIcon class="mr-2 size-4" />
          Author Activity
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable ref="table" :data="data" :columns="organizationActivities" :loading="loading" />
  <DataTableServerPagination v-show="!isQueryActive" v-bind="pagination" />
</template>
