<script setup lang="ts">
import { Sheet, SheetContent, SheetTrigger } from '@/components/modern/ui/sheet'
import { Button } from '@/components/modern/ui/button'
import { HamburgerMenuIcon } from '@radix-icons/vue'
import AppNavigationSidebar from '@/components/modern/app-navigation-sidebar'
import NotificationList from '@/components/NotificationList.vue'
import AppPageHeading from '@/components/modern/AppPageHeading.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
</script>

<template>
  <NotificationList />
  <div class="flex h-full max-h-full w-full max-w-full flex-col">
    <header class="flex h-fit flex-none items-center border-b bg-muted">
      <Sheet class="bg-muted">
        <SheetTrigger as-child
          ><Button variant="ghost" size="icon" class="hover:bg-white">
            <HamburgerMenuIcon /> </Button
        ></SheetTrigger>
        <SheetContent side="left" class="bg-muted">
          <AppNavigationSidebar class="w-full" />
        </SheetContent>
      </Sheet>
      <AppPageHeading class="grow" />
    </header>
    <div class="h-full min-w-0 flex-1">
      <main
        class="flex h-full flex-col overflow-y-auto pb-32"
        :class="{ 'p-2': !route.meta.isModern }"
      >
        <router-view />
      </main>
    </div>
  </div>
</template>
